import {
  Component,
  SimpleChanges,
} from "@angular/core";
import { AppConstant } from "src/app/app.constant";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as _ from "lodash";
import { CommonService } from "src/app/modules/services/shared/common.service";

@Component({
  selector: "app-prop-integration",
  templateUrl: "../integrations/integrations.component.html",
})
export class IntegrationsComponent {
  integrationForm: FormGroup;
  toolsList = [
    { id: 1, value: "Servicenow" },
    { id: 2, value: "JIRA" },
    { id: 3, value: "PagerDuty" },
    { id: 4, value: "Salesforce" },
  ];
  customerList= [];
  userstoragedata = {} as any;

  constructor(private fb: FormBuilder,
    private commonService: CommonService,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {
    this.integrationForm = this.fb.group({
      tools: [null, Validators.required],
      title:[null],
      impact: [null, Validators.required],
      notes: [null, Validators.required],
      customer: [null, Validators.required],
    });
    this.getCustomerList();
  }
  formArrayData(data, label, value) {
    let array = [] as any;
    data.forEach((element) => {
      let obj = {} as any;
      obj.label = element[label];
      obj.value = element[value];
      array.push(obj);
    });
    return array;
  }
  getCustomerList() {
    this.commonService
      .allCustomers({
        tenantid: this.userstoragedata.tenantid,
        status: AppConstant.STATUS.ACTIVE,
      })
      .subscribe((res) => {
        const response = JSON.parse(res._body);
        if (response.status) {
          this.customerList = this.formArrayData(
            response.data,
            "customername",
            "customerid"
          );
        } else {
          this.customerList = [];
        }
      });
  }
}