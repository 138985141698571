import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppConstant } from "src/app/app.constant";
import { OrchestrationService } from "src/app/business/base/orchestration/orchestration.service";
import { CommonService } from "src/app/modules/services/shared/common.service";
import { LocalStorageService } from "src/app/modules/services/shared/local-storage.service";

@Component({
  selector: "app-prop-envnode",
  templateUrl: "./deployment-options.component.html",
})
export class DeploymentOptionsComponent implements OnInit, OnChanges {
  deploymentForm: FormGroup;
  @Input() nodeObj = {};
  userstoragedata: any = {};
  orchestrationList = [];
  cloudproviderList = [];
  constructor(
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private orchestrationsService: OrchestrationService,
    private commonService: CommonService
  ) {
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {
    this.initForm();
  }
  getproviderList() {
    let condition = {} as any;
    condition = {
      lookupkey: AppConstant.LOOKUPKEY.CLOUDPROVIDER,
      status: AppConstant.STATUS.ACTIVE,
      tenantid: -1,
    };
    this.commonService.allLookupValues(condition).subscribe((res) => {
      const response = JSON.parse(res._body);
      if (response.status) {
        this.cloudproviderList = response.data;
      } else {
        this.cloudproviderList = [];
      }
    });
  }
  initForm() {
    this.deploymentForm = this.fb.group({
      autoswitch: [true],
      action: ["Yes", [Validators.required]],
      orchestration: [null, [Validators.required]],
      type: ["C", [Validators.required]],
      frequency: [null, [Validators.required]],
      tagid: [],
      tagvalue: [],
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
      ip: [null, [Validators.required]],
      storagetype: ["FTP", [Validators.required]],
      provider:[null, [Validators.required]],
      endpoint:[null, [Validators.required]],
      folder:[null, [Validators.required]],
      accesskey: [null, [Validators.required]],
      secretkey: [null, [Validators.required]],
    });
    this.getOrchestrationList();
    this.getproviderList();
  }
  getOrchestrationList() {
    let condition: any = {
      status: AppConstant.STATUS.ACTIVE,
      tenantid: this.userstoragedata.tenantid,
    };

    this.orchestrationsService.all(condition).subscribe((res) => {
      const response = JSON.parse(res._body);
      if (response.status) {
        this.orchestrationList = response.data;
      } else {
        this.orchestrationList = [];
      }
    });
  }
}
