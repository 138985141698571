import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-volumes",
  templateUrl:
    "../../../../presentation/web/deployments/ecl2/volumes/volumes.component.html",
})
export class VolumesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
