const SUB_TENANT = "Customer";
export const VMwareAppConstant = Object.freeze({
  columns: {
    VIRTUAL_MACHINES: [
      {
        field: "instancename",
        header: "Instance Name",
        isfilter: true,
        datatype: "string",
        isdefault: true,
        width: "20%",
      },
      {
        field: "region",
        header: "Region",
        datatype: "string",
        isdefault: true,
        width: "10%",
      },
      {
        field: "instancerefid",
        header: "Instance Id",
        isfilter: true,
        datatype: "string",
        isdefault: true,
        width: "10%",
      },
      {
        field: "privateipv4",
        header: "Private IP",
        isfilter: true,
        datatype: "string",
        isdefault: true,
        width: "10%",
      },
      {
        field: "ssmsgentid",
        header: "SSM Agent ID",
        datatype: "string",
        isdefault: false,
        width: "10%"
      },
      {
        field: "lastupdateddt",
        header: "Updated On",
        datatype: "timestamp",
        format: "dd-MMM-yyyy",
        isdefault: true,
        width: "10%",
      },

      {
        field: "customer",
        header: "Customer",
        datatype: "string",
        isdefault: false,
        width: "15%",
      },

      {
        field: "platform",
        header: "Platform",
        datatype: "string",
        isdefault: true,
        width: "10%",
      },
      {
        field: "lastupdatedby",
        header: "Updated By",
        datatype: "string",
        isdefault: true,
        width: "10%",
      },
      {
        field: 'vmstatus',
        header: 'Status',
        datatype: 'vmstatus',
        isdefault: true
      }
    ],
    CLUSTERS: [
      {
        datatype: "string",
        field: "clustername",
        header: "Cluster Name",
        value: "clustername",
        name: "Cluster Name",
        isfilter: true,
        isdefault: true,
      },
      {
        datatype: "string",
        field: "clusterrefid",
        header: "Cluster ID",
        value: "clusterrefid",
        name: "Cluster ID",
        isfilter: true,
        isdefault: true,
      },
      {
        datatype: "string",
        field: "drsstate",
        header: "DRS State",
        value: "drsstate",
        name: "DRS State",
        isfilter: true,
        isdefault: true,
      },
      {
        datatype: "string",
        field: "hastate",
        header: "HA state",
        value: "hastate",
        name: "HA state",
        isfilter: true,
        isdefault: true,
      },
      {
        datatype: "string",
        field: "customername",
        header: "Customer",
        name: "Customer",
        isdefault: false,
      },
      {
        field: "lastupdatedby",
        header: "Updated By",
        datatype: "string",
        isdefault: true,
      },
    ],
    DATACENTERS: [
      {
        datatype: "string",
        field: "dcname",
        header: "Datacenter Name",
        value: "dcname",
        name: "Datacenter Name",
        isfilter: true,
        isdefault: true,
      },
      {
        datatype: "string",
        field: "dcrefid",
        header: "Datacenter ID",
        value: "dcrefid",
        name: "Datacenter ID",
        isfilter: true,
        isdefault: true,
      },
      {
        datatype: "string",
        field: "customername",
        header: "Customer",
        name: "Customer",
        isdefault: false,
      },
      {
        field: "lastupdatedby",
        header: "Updated By",
        datatype: "string",
        isdefault: true,
      },
    ],
    VM_HOSTS: [
      {
        datatype: "string",
        field: "hostname",
        header: "Host Name",
        value: "hostname",
        name: "Host Name",
        isfilter: true,
        isdefault: true,
      },
      {
        datatype: "string",
        field: "hostrefid",
        header: "Host ID",
        value: "hostrefid",
        name: "Host ID",
        isfilter: true,
        isdefault: true,
      },
      {
        datatype: "string",
        field: "hoststate",
        header: "Host State",
        value: "hoststate",
        name: "Host State",
        isfilter: true,
        isdefault: true,
      },
      {
        datatype: "string",
        field: "powerstate",
        header: "Power State",
        value: "powerstate",
        name: "Power State",
        isfilter: true,
        isdefault: true,
      },
      {
        datatype: "string",
        field: "customername",
        header: "Customer",
        name: "Customer",
        isdefault: false,
      },
      {
        field: "lastupdatedby",
        header: "Updated By",
        datatype: "string",
        isdefault: true,
      },
    ],
  },
  Asset_Types: ["VIRTUAL_MACHINES", "CLUSTERS", "DATACENTERS", "VM_HOSTS"],
});
