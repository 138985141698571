import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConstant } from "src/app/app.constant";
import { HttpHandlerService } from "src/app/modules/services/http-handler.service";

@Injectable({
    providedIn: "root",
})
export class PipelineTemplateService {
    endpoint: string;
    serviceURL: any;
    constructor(private httpHandler: HttpHandlerService) {
        this.endpoint = AppConstant.API_END_POINT;
        this.serviceURL = AppConstant.API_CONFIG.API_URL.CICD.PIPELINETEMPLATE;
    }
    create(data): Observable<any> {
        return this.httpHandler.POST(this.endpoint + this.serviceURL.CREATE, data);
    }
    byId(id): Observable<any> {
        return this.httpHandler.GET(this.endpoint + this.serviceURL.FINDBYID + id);
    }
    update(id, data): Observable<any> {
        return this.httpHandler.POST(this.endpoint + this.serviceURL.UPDATE + id, data);
    }
}
