import { Component, OnInit, SimpleChanges, Input } from "@angular/core";
@Component({
  selector: "app-cloudmatiq-kpisetup",
  templateUrl:
    "../../../../presentation/web/tenant/servicemgmt/kpisetup/kpisetup.component.html"
})
export class KpiSetupComponent implements OnInit {
  selectedIndex = 0;
  constructor() {}
  ngOnInit() {}

}
