import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppConstant } from "src/app/app.constant";
import { UsersService } from "src/app/business/admin/users/users.service";
import { LocalStorageService } from "src/app/modules/services/shared/local-storage.service";

@Component({
  selector: "app-prop-rollback",
  templateUrl: "./rollback-retries.component.html"
})
export class RollbackRetriesComponent implements OnInit, OnChanges {
  rrForm: FormGroup;
  usersList = [];
  @Input() nodeObj = {};
  userstoragedata: any = {};
  @Input() isShowHideFields = true;
  @Input() propertiesName = ''
  rollbackRetriesErrObj = {
    retryTimeInterval: AppConstant.VALIDATIONS.ROLLBACK_RETRIES.RETRYTIMEINTERVAL,
    retryCount: AppConstant.VALIDATIONS.ROLLBACK_RETRIES.RETRYCOUNT,
    notifiers: AppConstant.VALIDATIONS.ROLLBACK_RETRIES.NOTIFIERS,
    rollbackMethod: AppConstant.VALIDATIONS.ROLLBACK_RETRIES.ROLLBACKMETHOD,
    rollbackOption: AppConstant.VALIDATIONS.ROLLBACK_RETRIES.ROLLBACKOPTION,
    orchestrator: AppConstant.VALIDATIONS.ROLLBACK_RETRIES.ORCHESTRATOR,
  };
  constructor(
    private userService: UsersService,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService
  ) {
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {
    this.initForm();
  }
  initForm() {
    this.rrForm = this.fb.group({
      retryTimeInterval: [300, [
        Validators.required,
        Validators.minLength(300),
      ]],
      retryCount: [0, [
        Validators.required,
        Validators.minLength(2),
      ]],
      notifiers: [null,[Validators.required]],
      rollbackMethod: ["automatic", [Validators.required]],
      rollbackOption: ["snapshot", [Validators.required]],
      orchestrator: [null, [Validators.required]]
    });
    this.getUsers();
  }
  getUsers() {
    this.userService
      .allUsers({
        tenantid: this.userstoragedata.tenantid,
        status: AppConstant.STATUS.ACTIVE,
      })
      .subscribe((res) => {
        const response = JSON.parse(res._body);
        if (response.status) {
          this.usersList = response.data;
        }
      });
  }
}
