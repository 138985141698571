import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import { NzMessageService } from "ng-zorro-antd";
import { AppConstant } from "src/app/app.constant";
import { LocalStorageService } from "src/app/modules/services/shared/local-storage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpHandlerService } from "src/app/modules/services/http-handler.service";

@Component({
  selector: "app-pipeline-settings",
  templateUrl: "./pipeline-settings.html"
})
export class PipelineTemplateProperties implements OnInit {
  @Input() propertiesobj: any;
  @Input() mode: boolean;
  @Output() propertiesChange = new EventEmitter<any>();
  templateTabIndex = 0 as number;
  
  constructor(
    private message: NzMessageService,
    private localStorageService: LocalStorageService,
    private routes: ActivatedRoute,
    private router: Router,
    private httpService: HttpHandlerService
  ) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.propertiesobj && changes.propertiesobj.currentValue) {
      this.propertiesobj = { ...changes.propertiesobj.currentValue };
    }
  }

  templateChange(event: any) {
    this.templateTabIndex = event.index;

    if (this.templateTabIndex === 0) {
      this.propertiesChange.emit(this.propertiesobj);
    }
  }
}
