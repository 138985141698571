import { Component, OnInit } from "@angular/core";
import { SrmService } from "../../srm.service";
import { NzMessageService } from "ng-zorro-antd";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { AppConstant } from "../../../../app.constant";
import { LocalStorageService } from "../../../../modules/services/shared/local-storage.service";
@Component({
  selector: "app-srm-service-list",
  templateUrl:
    "../../../../presentation/web/srm/service-request/service-list/service-list.component.html",
})
export class SRMServiceListComponent implements OnInit {
  catalogList = [];
  pendingList = [];
  catalogPublishedList = [];
  catalogPendingList = [];
  cardViewList = [];
  userstoragedata: any = {};
  searchText: string;
  pageTotal: any = 0;
  originalData: any = [];
  widthConfig: ["15px", "15px", "10px", "10px", "15px", "20px", "10px"];
  sortValue = null;
  sortName = null;
  listType = "card";
  loading = true;
  pageSize = 10;
  pageCount = AppConstant.pageCount;
  tab: any = AppConstant.STATUS.PUBLISHED;
  screens = [];
  appScreens = {} as any;
  // Actions
  createFlag = false;
  viewFlag = false;
  editFlag = false;
  deleteFlag = false;
  publishFlag = false;
  unpublishFlag = false;
  constructor(
    private router: Router,
    private srmService: SrmService,
    private localStorageService: LocalStorageService,
    private messageService: NzMessageService
  ) {
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
    this.screens = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.SCREENS
    );

    this.appScreens = _.find(this.screens, {
      screencode: AppConstant.SCREENCODES.SERVICE_CATALOG_MANAGEMENT,
    });

    if (_.includes(this.appScreens.actions, "Create")) {
      this.createFlag = true;
    }
    if (_.includes(this.appScreens.actions, "View")) {
      this.viewFlag = true;
    }
    if (_.includes(this.appScreens.actions, "Edit")) {
      this.editFlag = true;
    }
    if (_.includes(this.appScreens.actions, "Delete")) {
      this.deleteFlag = true;
    }
    if (_.includes(this.appScreens.actions, "Publish")) {
      this.publishFlag = true;
    }
    if (_.includes(this.appScreens.actions, "Unpublish")) {
      this.unpublishFlag = true;
    }
  }

  ngOnInit() {
    this.getSRMCatalogList();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.search();
  }
  search(): void {
    const data = this.originalData.filter((item) => item);
    if (this.sortName) {
      // tslint:disable-next-line:max-line-length
      this.catalogList = data.sort((a, b) =>
        this.sortValue === "ascend"
          ? a[this.sortName] > b[this.sortName]
            ? 1
            : -1
          : b[this.sortName] > a[this.sortName]
          ? 1
          : -1
      );
    } else {
      this.catalogList = data;
    }
  }
  imageExists(image_url) {
    try {
      console.log("function called");
      var http = new XMLHttpRequest();
      http.open("HEAD", image_url, false);
      http.send();
      return true;
    } catch (e) {
      return false;
    }
  }
  globalSearch(searchText: any) {
    if (searchText !== "" && searchText !== undefined && searchText != null) {
      const self = this;
      this.catalogList = [];
      this.originalData.map(function (item) {
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const element = item[key];
            const regxExp = new RegExp("\\b" + searchText, "gi");
            if (regxExp.test(element)) {
              if (!_.some(self.catalogList, item)) {
                if (item.publishstatus == self.tab) {
                  self.catalogList.push(item);
                  self.onPageChange(1);
                }
              }
            }
          }
        }
      });
    } else {
      this.catalogList = _.filter(this.originalData, {
        publishstatus: this.tab,
      });
    }
  }
  
  
  checkIndex(t) {
    if (t == 1 || t == 4 || t == 7) {
      return true;
    }
  }
  tabSelect(event) {
    this.catalogList = _.filter(this.originalData, { publishstatus: event });
    this.onPageChange(1);
    this.tab = event;
    this.searchText = '';
  }
  onPageSizeChange(size:number){
    this.pageSize = size;
    this.getSRMCatalogList()
    console.log("----",this.pageSize)
  }
  
  onPageChange(event) {
    let limit = (event - 1) * 9;
    this.cardViewList = this.catalogList.slice(limit, limit + 9);
    this.pageTotal = this.catalogList.length | 0;
  }
  getSRMCatalogList() {
    this.loading = true;
    let query = {} as any;
    query = { status: "Active", tenantid: this.userstoragedata.tenantid };
    this.srmService.allCatalog(query).subscribe((result) => {
      let response = {} as any;
      response = JSON.parse(result._body);
      if (response.status) {
        response.data.forEach((item) => {
          item.solutionname = "";
          if (item.solution) {
            item.solutionname = item.solution.solutionname;
          }
        });
        this.catalogList = response.data;
        this.catalogList = _.filter(this.catalogList, {
          publishstatus: this.tab,
        });
        this.onPageChange(1);
        this.originalData = response.data;
        this.loading = false;
      } else {
        this.catalogList = [];
        this.originalData = [];
        this.loading = false;
      }
    });
  }
  deleteCatalogService(data) {
    let formdata = {} as any;
    formdata = {
      catalogid: data.catalogid,
      status: AppConstant.STATUS.DELETED,
      lastupdatedby: this.userstoragedata.fullname,
      lastupdateddt: new Date(),
    };
    this.updateCatalog(formdata);

  }
  updateCatalog(formdata) {
    const formData = new FormData();
    formData.append("formData", JSON.stringify(formdata));
    this.srmService.updateCatalog(formData).subscribe((result) => {
      let response = {} as any;
      response = JSON.parse(result._body);
      if (response.status) {
        this.catalogList = this.catalogList.filter(item => item.catalogid !== formdata.catalogid);
        this.originalData = this.originalData.filter(item => item.catalogid !== formdata.catalogid);
        response.data.status === AppConstant.STATUS.DELETED
          ? this.messageService.success(
              "#" + response.data.catalogid + " Deleted Successfully"
            )
          : this.messageService.success(response.message);
          this.onPageChange(1);
      } else {
        this.messageService.error(response.message);
      }
    });
  }
    
  publish(data) {
    // let formdata = {} as any;
    // formdata = { catalogid: data.catalogid, publishstatus: AppConstant.STATUS.PUBLISHED, lastupdatedby: this.userstoragedata.fullname, lastupdateddt: new Date() };
    // this.updateCatalog(formdata);
    if (data) {
      if (
        data.publishstatus == "Pending" &&
        AppConstant.STATUS.PENDING &&
        new Date(data.publishdate) < new Date()
      ) {
        this.messageService.error("Publish date is expired");
        return;
      }
      // this.router.navigate(['srm/catalog/view/' + data.srvrequestid]);
      this.router.navigate(["srm/catalog/publish"], {
        queryParams: { id: data.catalogid, mode: "Publish" },
      });
    }
  }
  unpublish(data) {
    let formdata = {} as any;
    formdata = {
      catalogid: data.catalogid,
      publishstatus: AppConstant.STATUS.PENDING,
      lastupdatedby: this.userstoragedata.fullname,
      lastupdateddt: new Date(),
    };
    this.updateCatalog(formdata);
  }
  edit(data) {
    if (data) {
      this.router.navigate(["srm/catalog/edit/" + data.catalogid]);
    }
  }
  view(data) {
    if (data) {
      this.router.navigate(["srm/catalog/view"], {
        queryParams: { id: data.catalogid, mode: "View" },
      });
    }
  }
}
