import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import { NzMessageService } from "ng-zorro-antd";
import { AppConstant } from "src/app/app.constant";
import { LocalStorageService } from "src/app/modules/services/shared/local-storage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpHandlerService } from "src/app/modules/services/http-handler.service";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import {
  IResourceType,
  IAssetHdr,
} from "src/app/modules/interfaces/assetrecord.interface";
import { AssetRecordService } from "src/app/business/base/assetrecords/assetrecords.service";
import { AssetAttributesConstant } from "src/app/business/base/assetrecords/attributes.contant";

@Component({
  selector: "app-prop-cmdb",
  templateUrl: "./cmdb.component.html",
  styles: [
    `
    #tableContent th {
    border: 1px solid #dddddd30;
    padding: 8px;
    border-style: groove;
  }

    #tableContent td {
    border: 1px solid #dddddd30;
    padding: 6px;
    border-style: groove;
  }

    #tableContent th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #1c2e3cb3;
    color: white;
  }
    `
  ]
})
export class CMDBTemplateComponent implements OnInit {
  CMDBFrom: FormGroup;
  resourceTypesList: IResourceType[] = [];
  filteredColumns = [];
  incomingFilteredColumns = [];
  outgoingFilteredColumns: any[] = []; 
  defaultResourceType = "crn:ops:version";
  defaultAttributes = ['Key', 'Name', 'Created','Updated'];
  constructor(
    private localStorageService: LocalStorageService,
    private fb: FormBuilder,
    private assetRecordService: AssetRecordService
  ) { }

  ngOnChanges(changes: SimpleChanges): void { }

  ngOnInit() {
    this.getResourceType();
    this.CMDBFrom = this.fb.group({
      resourcetype: [null, [Validators.required]],
      attributes: [null, [Validators.required]],
      outgoingResources: this.fb.array([]),
    });
    this.addRow();
  }
  get outgoingResources(): FormArray {
    return this.CMDBFrom.get("outgoingResources") as FormArray;
  }
  addRow(): void {
    const resourceGroup = this.fb.group({
      outgoingResourcetype: [null, Validators.required],
      outgoingattributes: [null, Validators.required],
    });
    this.outgoingResources.push(resourceGroup);
    this.outgoingFilteredColumns.push([]);
  }
  deleteRow(index: number): void {
    this.outgoingResources.removeAt(index);
    this.outgoingFilteredColumns.splice(index, 1);
  }
  getResourceType() {
    this.assetRecordService
      .getResourceTypes({
        tenantid: this.localStorageService.getItem(
          AppConstant.LOCALSTORAGE.USER
        )["tenantid"],
      })
      .subscribe((d) => {
        let response: IResourceType[] = JSON.parse(d._body);
        this.resourceTypesList = _.orderBy(response, ["resource"], ["asc"]);
        this.CMDBFrom.get('resourcetype').setValue(this.defaultResourceType);
        this.getResourceDetail(this.defaultResourceType, {});
      });
  }

  getResourceDetail(crn: string, type, index?: number) {
    // this.CMDBFrom.patchValue({
    //   attributes: null,
    // });
      this.assetRecordService
        .getResource(
          this.localStorageService.getItem(AppConstant.LOCALSTORAGE.USER)[
          "tenantid"
          ],
          crn
        )
        .subscribe((d) => {
          let response: IAssetHdr[] = JSON.parse(d._body);
          this.filteredColumns = [];
          _.each(response, (itm: any, idx: number) => {
            itm.isSelected = itm.showbydefault ? true : false;
            if (itm.fieldtype != "Reference Asset") {
              this.filteredColumns.push(itm);
            }
            if (itm.fieldtype == "Reference Asset") {
              let referenceasset = JSON.parse(itm.referenceasset);
              _.map(referenceasset.attribute, (dtl) => {
                let attribute: any = _.find(
                  AssetAttributesConstant.COLUMNS[referenceasset.assettype],
                  {
                    field: dtl,
                  }
                );
                this.filteredColumns.push({
                  referencekey: attribute.referencekey,
                  fieldname: attribute.header,
                  fieldtype: itm.fieldtype,
                  fieldkey: attribute.field,
                  linkid: attribute.linkid,
                  referenceasset: referenceasset,
                  assettype: referenceasset.assettype,
                });
              });
            }
          });
          if (type === 'incoming') {
            this.incomingFilteredColumns = [
              ..._.orderBy(this.filteredColumns, ["ordernumber", "id", "asc"]),
            ];
            const defaultAttributeValues = this.defaultAttributes.filter(attr =>
              this.filteredColumns.find(col => col.fieldname === attr)
            );
            this.CMDBFrom.get('attributes').setValue(defaultAttributeValues);
          } else if (type === 'outgoing' && index !== undefined) {
            this.outgoingFilteredColumns[index] = [
              ..._.orderBy(this.filteredColumns, ["ordernumber", "id", "asc"]),
            ];
          }
        });
  }
}
