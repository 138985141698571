import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppConstant } from "src/app/app.constant";
import { LocalStorageService } from "src/app/modules/services/shared/local-storage.service";
import * as _ from "lodash";
import { PipelineTemplateService } from "../pipeline-template.service";

@Component({
  selector: "app-pipeline-validator",
  templateUrl: "./pipeline-validator.component.html",
  styleUrls: ["./pipeline-validator.component.css"],
})
export class PipelineValidatorComponent implements OnInit, OnChanges {
  userstoragedata: any = {};
  @Input() pipelineTemplateObj: any;
  @Input() defaultPipelineTemplateObj: any;
  @ViewChild("logTextarea") logTextarea: ElementRef;
  @Output() openSidebar = new EventEmitter<CustomEvent>();
  @Output() closeDrawer: EventEmitter<boolean> = new EventEmitter();
  screens = [];
  loading = true;
  txnLog = [];
  id: any;
  releaseName: string | null = null;
  nodes: any[] = [];
  lines: any[] = [];
  currentPageIndex: number = 0;
  queryParam: string = "";
  limit: string = "";
  selectedJob: string | null = null;
  selectedLogIdx: number = 0;
  selectedLog: any = {};
  constructor(
    private route: ActivatedRoute,
    private pipelineTemplateService: PipelineTemplateService,
    private localStorageService: LocalStorageService
  ) {
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
    this.pipelineTemplateObj = [];
    this.screens = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.SCREENS
    );

    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.id = params["id"];
        this.getById();
      } else if (params["mode"]) {
        if (params["mode"] === "defaulttemplate") {
          this.pipelineTemplateObj = this.defaultPipelineTemplateObj;
          this.processData(this.pipelineTemplateObj);
        }
      } else {
        this.pipelineTemplateObj = AppConstant.DEFAULTTEMPLATEOBJ;
        this.processData(this.pipelineTemplateObj);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["pipelineTemplateObj"] ||
      changes["defaultPipelineTemplateObj"]
    ) {
      this.processData(
        this.pipelineTemplateObj || this.defaultPipelineTemplateObj
      );
    }
  }  

  ngOnInit(): void {}

  getById() {
    this.pipelineTemplateService.byId(this.id).subscribe((result) => {
      const response = JSON.parse(result._body);
      if (response.status) {
        this.pipelineTemplateObj = response.data;
        this.releaseName = response.data.releaseName;
        this.loading = false;
      } else {
        this.loading = false;
        this.pipelineTemplateObj = [];
      }
    });
  }
    processData(templateObj: any): void {
      if (templateObj.pipelinetemplatedetails) {
        templateObj.pipelinetemplatedetails.forEach((item) => {
          const setupDetails = item.templatedetailconfig.setupdetails || {};
          let status = "PASS";
          let passNode = 0;
          let failNode = 0;
          let svgUrl = '';
          let requiredFields: string[] = [];
          let passFields: string[] = [];
          let failFields: string[] = [];
          switch (item.referencetype) {
            case "PROVIDER":
              svgUrl ='../../../../../assets/images/github.svg';
              requiredFields = ["name", "username", "url", "accesstoken"];
              break;
    
            case "CONTAINER_REGISTRY":
              svgUrl ='../../../../../assets/images/docker.svg';
              requiredFields = [
                "name",
                "username",
                "url",
                "imagename",
                "accesstoken",
              ];
              break;
    
            case "TESTING_TOOL":
              svgUrl ='../../../../../assets/images/sonarqube.svg';
              requiredFields = ["name", "url", "organization", "accesstoken"];
              break;
    
            case "ENVIRONMENTS":
              svgUrl ='../../../../../assets/images/vm.svg';
              requiredFields = ["ipaddress", "username", "password", "port"];
              break;
    
            case "BUILD":
              svgUrl ='../../../../../assets/images/build.svg';
              requiredFields = [
                "name",
                "ipaddress",
                "username",
                "password",
                "buildscript",
                "instancename",
              ];
              break;

              case "ORCHESTRATION":
                svgUrl ='../../../../../assets/images/orch.svg';
                requiredFields = [
                  "name"
                ];
                break;
    
                case "APPROVAL_WORKFLOW":
                svgUrl ='../../../../../assets/images/workflow.svg';
                requiredFields = [
                  "name",
                  "approverlevel1"
                ];
                break;
    
            default:
              requiredFields = [];
              status = "PASS";
              break;
          }

          requiredFields.forEach((field) => {
            const value = setupDetails[field];
            if (value !== null && value !== undefined && value.toString().trim() !== '') {
              passFields.push(field.charAt(0).toUpperCase() + field.slice(1));
              passNode++;
            } else {
              failFields.push(field.charAt(0).toUpperCase() + field.slice(1));
              failNode++;
            }
          });
    
          if (failNode > 0) {
            status = "FAIL";
          }  
          this.nodes.push({
            id: item.id,
            name: item.providerjobname,
            status: status,
            passnode: passNode,
            failnode: failNode,
            position: item.position,
            referenceType: item.referencetype,
            passfields: passFields,
            failfields: failFields,
            svgImg: svgUrl 
          });
        });
      }
    }
  
  showMessage(nodeId: number, status: string) {
    const node = this.nodes.find((n) => n.id === nodeId);
    if (node) {
      node.status = status;
    }
  }
  onFieldChange(nodeType: string) {
    const nodes =
      this.pipelineTemplateObj
        ? this.pipelineTemplateObj.pipelineflow.nodes || this.pipelineTemplateObj.nodes
        :  this.defaultPipelineTemplateObj.nodes;
    const node = nodes.find((n) => n.name === nodeType);
    if (node) {
      const selectedDetails = node.data;
      let detail = {
        pipelineTemplateData:
          (this.pipelineTemplateObj &&
            this.pipelineTemplateObj.pipelinetemplatedetails) ||
          this.defaultPipelineTemplateObj.pipelinetemplatedetails,
        selectedNode: {
          referenceid: node.referenceid,
          name: node.referencetype,
          _label: selectedDetails.name || selectedDetails.instancename,
          _data: selectedDetails,
        },
      };
      const customEvent = new CustomEvent("openSidebar", { detail });
      this.openSidebar.emit(customEvent);
      this.closeDrawer.emit(false);
    }
  }
}
