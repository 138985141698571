import { Component, OnInit } from "@angular/core";
import { TenantsService } from "../../tenants/tenants.service";
import { AppConstant } from "../../../app.constant";
import { Router } from "@angular/router";
import { LocalStorageService } from "../../../modules/services/shared/local-storage.service";
import { NzMessageService } from "ng-zorro-antd";
import * as _ from "lodash";

@Component({
  selector: "app-tenant",
  templateUrl: "../../../presentation/web/admin/tenant/tenant.component.html",
})
export class TenantComponent implements OnInit {
  loading = true;
  tenantList = [];
  totalCount = null;
  isVisible = false;
  formTitle = "Add Tenant";
  buttonText = "Add Tenant";
  tenantObj: any = {};
  userstoragedata: any = {};
  screens = [];
  appScreens: any = {};
  visibleadd = false;
  tenantListHeaders = [
    { field: "tenantname", header: "Name", datatype: "string" },
    { field: "contactemail", header: "Email ID", datatype: "string" },
    { field: "pphoneno", header: "Phone No", datatype: "string" },
    { field: "lastupdatedby", header: "Updated By", datatype: "string" },
    {
      field: "lastupdateddt",
      header: "Updated On",
      datatype: "timestamp",
      format: "dd-MMM-yyyy hh:mm:ss",
    },
    { field: "status", header: "Status", datatype: "string" },
  ];

  tenantTableConfig = {
    edit: false,
    delete: false,
    globalsearch: true,
    pagination: true,
    loading: false,
    pageSize: 10,
    title: "",
    widthConfig: ["25px", "25px", "25px", "25px", "25px", "25px"],
  };
  constructor(
    private tenantsService: TenantsService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private message: NzMessageService
  ) {
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
    this.screens = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.SCREENS
    );
    this.appScreens = _.find(this.screens, {
      screencode: AppConstant.SCREENCODES.TENANTS,
    });
    if (_.includes(this.appScreens.actions, "Create")) {
      this.visibleadd = true;
    }
    if (_.includes(this.appScreens.actions, "Edit")) {
      this.tenantTableConfig.edit = true;
    }
    if (_.includes(this.appScreens.actions, "Delete")) {
      this.tenantTableConfig.delete = true;
    }
  }

  ngOnInit() {
    this.getTenantList();
  }
  getTenantList() {
    this.tenantsService
      .all({ status: AppConstant.STATUS.ACTIVE })
      .subscribe((res) => {
        const response = JSON.parse(res._body);
        if (response.status) {
          this.loading = false;
          this.tenantList = response.data;
          this.totalCount = this.tenantList.length;
        } else {
          this.loading = false;
          this.tenantList = [];
        }
      });
  }
  onChanged(val) {
    this.isVisible = val;
  }

  dataChanged(result) {
    if (result.edit) {
      this.isVisible = true;
      this.router.navigate(["tenant/edit", result.data.tenantid]);
    } else if (result.delete) {
      this.deleteRecord(result.data);
    }
  }
  deleteRecord(data) {
    const formdata = {
      tenantid: data.tenantid,
      status: AppConstant.STATUS.DELETED,
      lastupdatedby: this.userstoragedata.fullname,
      lastupdateddt: new Date(),
    };
    const formData = new FormData();
    formData.append("formData", JSON.stringify(formdata));
    this.tenantsService.update(formData).subscribe((result) => {
      let response = {} as any;
      response = JSON.parse(result._body);
      if (response.status) {
        response.data.status === AppConstant.STATUS.DELETED
          ? this.message.success(
              "#" + response.data.tenantid + " Deleted Successfully"
            )
          : this.message.success(response.message);
        this.getTenantList();
      } else {
        this.message.error(response.message);
      }
    });
  }
}
