import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from "src/app/modules/services/shared/local-storage.service";
import { HttpHandlerService } from "src/app/modules/services/http-handler.service";
import { DatePipe } from "@angular/common";
import { AppConstant } from "src/app/app.constant";
import { CicdService } from "../cicd.service";
import * as _ from "lodash";
@Component({
  selector: "app-pipeline-template",
  templateUrl: "./pipeline-template.component.html",
  styleUrls: ["./pipeline-template.component.css"],
})
export class PipelineTemplateComponent implements OnInit {
  visible = false;
  searchText: string;
  status = AppConstant.CICD.status;
  pipelineName: string = "";
  selectedDate: Date[] = [];
  selectedStatus: string = null;
  userstoragedata = {} as any;
  templateList = [];
  screens = [];
  appScreens = {} as any;
  loading = false;
  totalCount;
  limit = 10;
  offset = 0;
  order = ["lastupdateddt", "desc"];
  pageIndex = 1;
  filterValues: any = {};
  isVisible = false;
  isEncrypted: boolean = false;
  isLimitChnaged: boolean = false;
  tableHeader = [
    { field: "pipelinename", header: "Pipeline Name", datatype: "string" },
    { field: "createdby", header: "Created By", datatype: "string" },
    {
      field: "createddt",
      header: "Created On",
      datatype: "timestamp",
      format: "dd-MMM-yyyy hh:mm:ss",
    },
    { field: "lastupdatedby", header: "Updated By", datatype: "string" },
    {
      field: "lastupdateddt",
      header: "Updated On",
      datatype: "timestamp",
      format: "dd-MMM-yyyy hh:mm:ss",
    },
    { field: "status", header: "Status", datatype: "string" },
  ] as any;
  tableConfig = {
    edit: true,
    view: true,
    delete: false,
    clone: true,
    loading: false,
    apisort: true,
    pagination: false,
    frontpagination: false,
    manualpagination: false,
    pageSize: 10,
    count: 0,
    scroll: { x: "1000px" },
    title: "",
    widthConfig: [
      "100px",
      "200px",
      "100px",
      "100px",
      "100px",
      "100px",
      "100px",
    ],
  } as any;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private httpService: HttpHandlerService,
    private datePipe: DatePipe,
    private routes: ActivatedRoute,
    private cicdService: CicdService,
  ) {
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
    this.templateList = [];
    this.screens = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.SCREENS
    );
    this.routes.queryParams.subscribe((params) => {
      this.handleQueryParams(params);
    });
    if (this.pageIndex > 1) {
      this.offset = this.calculateOffset(this.pageIndex, this.limit);
    };
    if (_.includes(this.appScreens.actions, "Copy")) {
      this.tableConfig.clone = true;
    };
  }

  handleQueryParams(params: any) {
    if (params['page']) {
      this.pageIndex = parseInt(params["page"])
    }
    if (params['q']) {
      this.filterValues = JSON.parse(this.cicdService.decrypt(params['q']));
      if (this.filterValues.pipelinename && this.filterValues.pipelinename !== '') {
        this.pipelineName = this.filterValues.pipelinename;
      };
      if (this.filterValues.status && this.filterValues.status !== '') {
        this.selectedStatus = this.filterValues.status;
      };
      if (this.filterValues.date && this.filterValues.date.length == 2) {
        this.selectedDate = this.filterValues.date;
      };
    };

    if (params['limit']) {
      const limit = parseInt(params['limit'])
      this.tableConfig.pageSize = limit;
      this.limit = limit;
    };
  }

  ngOnInit() {
    this.loading = false;
    this.getAllTemplateList(this.limit, this.offset);
  }

  calculateOffset(currentPage: number, limit: number) {
    return (currentPage - 1) * limit;
  }

  getAllTemplateList(limit?, offset?) {
    let query: any =
      AppConstant.API_END_POINT +
      AppConstant.API_CONFIG.API_URL.CICD.PIPELINETEMPLATE.FINDALL +
      `?tenantid=${this.userstoragedata.tenantid}&count=${true}&limit=${limit || 10}&offset=${offset || 0}`;
    if (this.pipelineName && this.pipelineName !== '') {
      query += `&pipelinename=${this.pipelineName}`;
      this.filterValues.pipelinename = this.pipelineName;
    }
    if (this.selectedStatus && this.selectedStatus !== '') {
      query += `&status=${this.selectedStatus}`;
      this.filterValues.status = this.selectedStatus;
    }
    if (this.selectedDate && this.selectedDate.length == 2) {
      const startDate = this.datePipe.transform(
        this.selectedDate[0],
        "yyyy-MM-dd"
      );
      const endDate = this.datePipe.transform(
        this.selectedDate[1],
        "yyyy-MM-dd"
      );
      query += `&startDate=${startDate}&endDate=${endDate}`;
      this.filterValues.date = this.selectedDate;
    }
    this.loading = true;
    this.httpService.GET(query).subscribe((result) => {
      try {
        const response = JSON.parse(result._body);
        if (response.status) {
          this.handleResponse(response);
        } else {
          this.totalCount = 0;
          this.templateList = [];
          this.loading = false;
        }
      } catch (error) {
        this.templateList = [];
        this.loading = false;
      }
    }, (error) => {
      this.templateList = [];
      this.loading = false;
    });
  };

  handleResponse(response: any) {
    if (response.data.rows.length == 0) {
      this.pageIndex = this.getLastPage(response.data.count, this.limit);
      this.offset = this.calculateOffset(this.pageIndex, this.limit);
      this.router.navigate([], {
        queryParams: {
          page: this.pageIndex,
        },
      });
      this.getAllTemplateList(this.limit, this.offset);
    } else {
      this.loading = false;
      this.totalCount = response.data.count;
      this.tableConfig.manualpagination = true;
      this.tableConfig.count = "Total Records" + ":" + " " + this.totalCount;
      this.templateList = response.data.rows;
      this.templateList.forEach(k => {
        k.isEditable = k.status === AppConstant.STATUS.INACTIVE;
        k.isViewable = k.status === AppConstant.STATUS.INACTIVE;
        k.isCloneable = k.status === AppConstant.STATUS.INACTIVE;
      });
    };
  };

  showFilter(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  applyFilters() {
    this.visible = false;
    this.offset = 0;
    this.pageIndex = 1;
    this.getAllTemplateList(this.limit, this.offset);
  }

  clearFilters() {
    this.pipelineName = "";
    this.selectedDate = [];
    this.selectedStatus = null;
    this.filterValues = {};
    this.pageIndex = 1;
    this.offset = 0;
    this.getAllTemplateList(this.limit, this.offset);
  }

  dataChanged(e) {
    if (e.pagination) {
      if (e.pagination.limit > 10) {
        this.limit = e.pagination.limit;
        if (this.pageIndex == 1) {
          this.isLimitChnaged = true;
        };
      };
      const currentPageIndex = Math.floor((e.pagination.offset || 0) / e.pagination.limit) + 1;
      if ((this.pageIndex !== currentPageIndex) || this.isLimitChnaged) {
        this.pageIndex = currentPageIndex;
        this.getAllTemplateList(e.pagination.limit, e.pagination.offset);
        this.isLimitChnaged = false;
      }
      this.router.navigate([], {
        queryParams: {
          page: this.pageIndex,
        },
      });
      this.tableConfig.pageSize = e.pagination.limit;
    } else if (e.edit) {
      const queryParams = this.getQueryParams();
      this.router.navigate(["cicd/pipelinetemplate/update/" + e.data.id], {
        queryParams
      });
    } else if (e.view) {
      const queryParams = this.getQueryParams();
      this.router.navigate(["cicd/pipelinetemplate/view/" + e.data.id], {
        queryParams
      });
    }
    else if (e.clone) {
      const queryParams = this.getQueryParams();
      this.router.navigate(["cicd/pipelinetemplate/copy/" + e.data.id], {
        queryParams
      });
    }
  }

  createTemplate() {
    this.router.navigate(["cicd/pipelinetemplate/createpipelinetemplate"]);
  }

  reload() {
    this.offset = this.calculateOffset(this.pageIndex, this.limit);
    this.getAllTemplateList(this.limit, this.offset);
  }

  getQueryParams(): any {
    let queryParams: any = {
      page: this.pageIndex,
    };
    if (!_.isEmpty(this.filterValues)) {
      if (this.isEncrypted) {
        this.filterValues = this.cicdService.decrypt(this.filterValues);
        this.isEncrypted = false;
      } else {
        this.filterValues = this.cicdService.encrypt(JSON.stringify(this.filterValues));
        this.isEncrypted = true;
      }
      queryParams.q = this.filterValues;
    };
    if (this.limit > 10) {
      queryParams.limit = this.limit;
    }
    return queryParams;
  }

  getLastPage(totalRecords: number, recordsPerPage: number): number {
    return Math.ceil(totalRecords / recordsPerPage);
  }

  openModel(): void {
    this.isVisible = true;
  }

  handleCancel($event: MouseEvent): void {
    this.isVisible = false;
  }

  templates = [
    { id: 1, name: 'Build and Deploy' },
    { id: 2, name: 'Automated Testing' },
    { id: 3, name: 'Continuous Deployment'},
    { id: 4, name: 'Build and Test'}
  ];
  navigateToTemplate(template): void {
    this.isVisible = false; 
      const queryParams = this.getQueryParams();
      this.router.navigate([`cicd/pipelinetemplate/defaulttemplate/${template.name}`],{
        queryParams
      });
  }

}
