import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AppConstant } from "src/app/app.constant";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UsersService } from "src/app/business/admin/users/users.service";
import { LocalStorageService } from "src/app/modules/services/shared/local-storage.service";
import { OrchestrationService } from "../../../../base/orchestration/orchestration.service";
import { ScriptService } from "../../../../tenants/scripts/script.service";

@Component({
  selector: "app-prop-notification",
  templateUrl: "../notifications/notifications.component.html",
})
export class PropNotificationComponent {
  ntfForm: FormGroup;
  usersList = [];
  @Input() nodeObj = {};
  userstoragedata: any = {};
  orchestrationList = [];
  scriptList = [];
  dataLoading = false;
  configuredNotifications = [
    {
      type: "Alert",
      notifiers: [],
      configuration: "",
    },
  ];
  notificationErrObj = {
    eventType: AppConstant.VALIDATIONS.NOTIFICATIONSMSG.EVENTTYPE,
    notifiers: AppConstant.VALIDATIONS.NOTIFICATIONSMSG.NOTIFIERS,
    remediationType: AppConstant.VALIDATIONS.NOTIFICATIONSMSG.REMEDIATIONTYPE,
  };
  constructor(
    private userService: UsersService,
    private fb: FormBuilder,
    private orchestrationService: OrchestrationService,
    private scriptService: ScriptService,
    private localStorageService: LocalStorageService
  ) {
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {
    this.initForm();
  }
  initForm() {
    this.ntfForm = this.fb.group({
      notifiers: [null, [Validators.required]],
      remediationType: [null, [Validators.required]],
      eventType: [null, [Validators.required]],
      script: [null, []],
      orchestration: [null, []],
    });
    this.getUsers();
    this.getOrchestrationList();
    this.getScriptList();
  }
  getOrchestrationList() {
    let condition: any = {
      status: AppConstant.STATUS.ACTIVE,
      tenantid: this.userstoragedata.tenantid,
    };

    this.orchestrationService.all(condition).subscribe((res) => {
      const response = JSON.parse(res._body);
      if (response.status) {
        this.orchestrationList = response.data;
      } else {
        this.orchestrationList = [];
      }
    });
  }
  getUsers() {
    this.userService
      .allUsers({
        tenantid: this.userstoragedata.tenantid,
        status: AppConstant.STATUS.ACTIVE,
      })
      .subscribe((res) => {
        const response = JSON.parse(res._body);
        if (response.status) {
          this.usersList = response.data;
        }
      });
  }
  getScriptList() {
    let condition: any = {
      status: AppConstant.STATUS.ACTIVE,
      tenantid: this.userstoragedata.tenantid,
    };
    this.scriptService.all(condition).subscribe((result) => {
      let response = {} as any;
      response = JSON.parse(result._body);
      if (response.status) {
        this.scriptList = response.data;
      } else {
        this.scriptList = [];
      }
    });
  }

  deleteNotification(index: number): void {
    this.configuredNotifications.splice(index, 1);
  }
  getReceiversFor(receivers: string[]): string {
    return receivers.join(", ");
  }
}
