import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppConstant } from "src/app/app.constant";
import { LocalStorageService } from "src/app/modules/services/shared/local-storage.service";
import { TenantsService } from "../../../../tenants/tenants.service";
import { CommonService } from "../../../../../modules/services/shared/common.service";
import * as _ from "lodash";
import { AWSAppConstant } from "src/app/aws.constant";
import { NzMessageService, NzNotificationService } from "ng-zorro-antd";
import * as moment from "moment";
import { WorkpackConstant } from "src/app/workpack.constant";
import { AssetRecordService } from "src/app/business/base/assetrecords/assetrecords.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  IResourceType,
  IAssetHdr,
  IAssetDtl,
} from "src/app/modules/interfaces/assetrecord.interface";
import downloadService from "src/app/modules/services/shared/download.service";
import { Buffer } from "buffer";
import { Observable } from "rxjs";
import { UsersService } from "src/app/business/admin/users/users.service";
@Component({
  selector: "app-addeditworkpacktemplate",
  templateUrl: "./addedittemplate.component.html",
  styles: [
    `
      ::ng-deep .ant-collapse-content {
        background-color: #475560 !important;
      }
      ::ng-deep .ant-btn[disabled]{
        background-color: transparent;
      }
      ::ng-deep .ant-btn[disabled]:hover{
        background-color: transparent;
      }
      ::ng-deep .ant-form-item-label label:after {
        margin: 0 !important;
      }
    `,
  ],
})
export class AddedittemplateComponent implements OnInit {
  @Output() notifyUpdateEntry: EventEmitter<any> = new EventEmitter();
  @Input() modelView: boolean = false;
  @Input() ExecuteTemplate:boolean=false;
  @Input() selectedCRN:string="";
  @Input() selectedResourceID:string="";
  // notifyNewEntry:any;
  resourceId;
  temptabIndex = 0;
  templateObj: any = {};
  loading = false;
  templateForm: FormGroup;
  customerList = [];
  categoryList = [];
  subcategoryList = [];
  severityList = [];
  impactList = [];
  urgencyList = [];
  contacttypes = [];
  templatestatusList = [];
  componentList = [];
  assignmentgroups = [];
  statusList = [
    AppConstant.STATUS.ACTIVE,
    AppConstant.STATUS.INACTIVE,
    AppConstant.STATUS.DELETED,
  ];
  userstoragedata: any;
  button = "Save";
  addCategory: boolean = false;
  edit = false;
  selectedIndex = 0;
  templateErrObj = {
    customerid: AWSAppConstant.VALIDATIONS.TICKETS.CUSTOMERNAME,
    category: AWSAppConstant.VALIDATIONS.TICKETS.CATEGORY,
    templateno: AWSAppConstant.VALIDATIONS.TICKETS.INCIDENTNo,
    title: AWSAppConstant.VALIDATIONS.TICKETS.TITLE,
    displaytitle: AWSAppConstant.VALIDATIONS.TICKETS.DISPLAYTITLE,
    severity: AWSAppConstant.VALIDATIONS.TICKETS.SEVERITY,
    notes: AWSAppConstant.VALIDATIONS.TICKETS.NOTES,
  };
  taskstemplateHeader = WorkpackConstant.COLUMNS.TASKSTEMPLATES;
  taskMode: string = "Add";
  taskstemplatesList: any[] = [];
  formTitle: string = "Add Task";
  isVisible: boolean = false;
  tasktemplateObj: any = {};
  selectedResource = [];
  selectedTaskResource = [];
  filteredColumns = [];
  selectedResourceName = "";
  taskResource = [];
  taskResourceFormattedHeader = [];
  taskResourceFormattedData = [];
  taskResourceName = "";
  resource = {};
  isAddForm = true;
  resourceData = [];
  recordData = [];
  crnData = [];
  addformloading = false;
  resourceDetailMenuVisible = false;
  taskValueAssets = [];
  selectedTaskResourceId = [];
  editResourceId = "";
  crndetails = [];
  org_crndetails = [];
  taskResourceTypeList: any[] = [];
  selectedTaskResourceName: any;
  resourceDetails = {} as any;
  isEdit = false;
  selectedTaskEdit: any;
  referringAssetDetails = [];
  oldTaskTemplate;
  workflowformTitle="Workflow";
  selectedWorkflowTask = null;
  isworkflowVisible=false;
  resoruceTitle="";
  waiterSubscriber: any;
  waiterInterval:any;
  interval: any;
  usersList:any[];
  cmdb_operationtype=AppConstant.CMDB_OPERATIONTYPE;
  workpack_operationtype = AppConstant.WORKPACK_OPERATIONTYPE;
  workpack_defaultAttr=AppConstant.WORKPACK_DEFAULT_ATTR;
  workpack_status=this.workpack_operationtype[0];
  txnrefList:any[]=[];
  isExecutableVersion=false;
  isExcecutorLogin=false;
  isReviewerLogin=false;
  isTreeVisible=false;
  selectedWorkpackTitle='';
  selectedTaskDetails:any[]=[];
  isPublished:boolean=false;
  screens = [];
  appScreens = {} as any;
  visibleadd = false;
  visibleonly = false;
  visibleEdit = false;
  visibleDelete = false;
  visiblePublish = false;
  saveloading:boolean=false;
  executionStatus:any;
  executionStatusList = AppConstant.WORKPACK_EXECUTIONSTATUS;
  selectedFields:any[];
  workflow_config={
    assignee_placeholder : "Executor/Installer",
    tabltitle_info:"Workflow Execution",
    tabltitle_comments : "Remarks/Observations",
    tabltitle_document : "Documents"
  }
  srcfrom="";
  runID:any="";
  watchList:any[]=[];
  selectedTaskResourceID;
  constructor(
    private fb: FormBuilder,
    private tenantsService: TenantsService,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private message: NzMessageService,
    private notification: NzNotificationService,
    private assetRecordService: AssetRecordService,
    public router: Router,
    private routes: ActivatedRoute,
    private userService: UsersService,
  ) {
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
    this.screens = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.SCREENS
    );
    let workpack_screencode:any=AppConstant.SCREENCODES.WORKPACKTEMPLATE;
    this.appScreens = _.find(this.screens, {
      screencode: workpack_screencode
    });
    if(this.appScreens){
      if (_.includes(this.appScreens.actions, "View")) {
        this.visibleonly = true;
      }
      if (_.includes(this.appScreens.actions, "Create")) {
        this.visibleadd = true;
      }
      if (_.includes(this.appScreens.actions, "Edit")) {
        this.visibleEdit = true;
      }
      if (_.includes(this.appScreens.actions, "Delete")) {
        this.visibleDelete = true;
      }
      if (_.includes(this.appScreens.actions, "Publish")) {
        this.visiblePublish = true;
      }
    }
    this.waiterSubscriber = new Observable((observer) => {
      this.waiterInterval = setInterval(() => { 
        this.userstoragedata = this.localStorageService.getItem(
          AppConstant.LOCALSTORAGE.USER
        );
        observer.next(this.userstoragedata);
      }, 1000);
    });
    this.waitForIntialize();
    // this.initForm();
    // this.getCustomerList();
    // this.getCategoryList();
    // this.getSubCategoryList();
  }
  waitForIntialize(router?:any,callback?:any,params?:any){
    this.waiterSubscriber.subscribe((res) => {
     let localstoreage = res;
     if(this.waiterInterval && localstoreage) {
      this.initForm();
      this.getCustomerList();
      this.getCategoryList();
      this.getSubCategoryList();
      clearInterval(this.waiterInterval);
     }
   });
   }
  ngOnInit() {
    this.getUserList();
    this.routes.queryParamMap.subscribe((p: any) => {
      if (p.params && !this.ExecuteTemplate) {
        if (p.params.modelcrn != undefined) {
          this.selectedResourceName = p.params.modelcrn;
          this.getResourceDetail(this.selectedResourceName);
        } else if (p.params.resource != undefined) {
          this.selectedResourceName = p.params.resource.split("/")[0];
          this.editResourceId = p.params.resource.split("/")[1];
          this.resourceId = p.params.resource;
          this.editDetails(p.params.resource);
          this.isEdit = true;
        }
        if(p.params.srcfrom){
          // executionlist or email
          this.srcfrom = p.params.srcfrom;
        }
        if(p.params.srcfrom == "email"){
          setTimeout(() => {
            this.assignWorkflow();
          }, 3000);
        }
        setTimeout(() => {
          //   this.getTemplateAssets();
        }, 1000);
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.initForm();
    if (
      !_.isUndefined(changes.templateObj) &&
      !_.isEmpty(changes.templateObj.currentValue)
    ) {
      this.edit = true;
      this.editForm(changes.templateObj.currentValue);
    } else {
      this.edit = false;
    }
    if (
      !_.isUndefined(changes.selectedCRN) &&
      !_.isEmpty(changes.selectedCRN)
    ) {
      if (this.selectedCRN != undefined && this.selectedCRN != "") {
        this.selectedResourceName = this.selectedCRN;
        this.getResourceDetail(this.selectedResourceName);
      } 
      else if (this.selectedResourceID != undefined && this.selectedResourceID != "") {
        this.selectedResourceName = this.selectedResourceID.split("/")[0];
        this.editResourceId = this.selectedResourceID.split("/")[1];
        this.resourceId = this.selectedResourceID;
        this.editDetails(this.selectedResourceID);
        this.isEdit = true;
      }
    }
    
    this.getCustomerList();
    this.getCategoryList();
    this.getSubCategoryList();
  }
  tabChanged(event) {
    this.temptabIndex = event.index;
  }
  getUserList() {
    this.userService
      .allUsers({
        tenantid: this.userstoragedata.tenantid,
        status: AppConstant.STATUS.ACTIVE,
      })
      .subscribe((res) => {
        const response = JSON.parse(res._body);
        if (response.status) {
          // this.usersList = response.data;
          this.usersList = _.map(response.data,(u)=>{
            u.userid = u.userid.toString();
            return u;
          })
        } 
      });
  }
  loadReferences() {
    this.recordData = this.selectedResource;
    let crns = [];
    this.taskResourceTypeList = [];
    _.filter(this.recordData, (item, idx) => {
      this.resourceData[idx] = [];
      if (item.fieldtype == "REFERENCE") {
        if (item.crn == this.selectedTaskResourceName) {
          this.taskResourceTypeList.push(item);
        }
        crns.push(item.relation);
        return item;
      }
    });
    this.getAllCRNs(crns);
  }
  editDetails(resourceid) {
    // load workpack task details
    this.assetRecordService
    .txnrefList(
      {
        refkey :resourceid,
        // module : 'workpack-task',
        status : AppConstant.STATUS.ACTIVE
      }
    )
    .subscribe((taskstxndata) => {
      let refsres:any = JSON.parse(taskstxndata._body);
      if(refsres.data){
        this.txnrefList=refsres.data || [];
        let executesres:any[] = _.filter(_.cloneDeep(this.txnrefList),(t:any)=>{
          return (t.module == 'workpack-executable')
        });
        if(executesres){
          if(executesres.length > 0){
            this.runID=executesres[0].txnid;
            this.isExecutableVersion = true;
          }
        }
        let tasksres:any[] = _.filter(_.cloneDeep(this.txnrefList),(t:any)=>{
          return (t.module == 'workpack-task')
        });
        let selectedTaskResourceId=[];
        let reference = "";
        let selectedWorkpackTitle="";
        _.each(tasksres,(t)=>{
          let transaction={
            id: t.id,
            txn : t.txn,
            reference : t.reference,
            notes : t.notes,
            module : 'workpack-task'
          }
          this.selectedTaskDetails.push(transaction);
          selectedTaskResourceId.push(t.txn);
          reference=t.reference;
          selectedWorkpackTitle=t.notes;
        });

        _.each(_.cloneDeep(this.txnrefList),(t:any)=>{
          if(t.module === this.cmdb_operationtype[7]){
            this.watchList.push(t.txn);
            this.watchList = [...this.watchList];
          }
        });

        this.selectedTaskResourceId = selectedTaskResourceId;
        this.selectedTaskResourceName = reference;
        this.selectedWorkpackTitle = selectedWorkpackTitle;
        this.changeTaskResourceType(reference);
      }
    });

    // get resource details
    this.assetRecordService
    .getResource(
      this.localStorageService.getItem(AppConstant.LOCALSTORAGE.USER)[
      "tenantid"
      ],
      this.selectedResourceName
    )
    .subscribe((d) => {
      let hdrresponse = JSON.parse(d._body);
      this.selectedResource = hdrresponse;
      this.assetRecordService
        .getResourceValuesById(btoa(resourceid))
        .subscribe((r) => {
          this.crndetails = JSON.parse(r._body).data;
          this.org_crndetails = _.cloneDeep(JSON.parse(r._body).data);
          this.resourceDetails.details = this.crndetails;
          this.getTaskTemplatesOnEdit();
          this.loadReferences();
        });
    });
    
    // call api to get the header and details of the assets
    this.assetRecordService
    .txnrefList(
      {
        txn : resourceid
      }
    )
    .subscribe((txndata) => {
      let res = JSON.parse(txndata._body);
        if(res.status){
          this.txnrefList=res.data || [];
        }
     
    });
   
  }
  getTaskTemplatesOnEdit() {
    let parseJson = (str) => {
      try {
        let parse = JSON.parse(str);
        return parse;
      } catch (error) {
        return "";
      }
    };
    _.each(this.resourceDetails.details, (d) => {
      let fieldvalue = parseJson(d.fieldvalue);
      if (_.isArray(fieldvalue)) {
        let tasktemplatedtl = _.find(fieldvalue, (t) => {
          console.log("field: ", t);
          if(t){
            return t.mode == AppConstant.WORKPACK_CONFIG.WP_TASK_KEY;
          }
          
        });
        if (tasktemplatedtl) {
          console.log(tasktemplatedtl);
          this.selectedTaskResourceName = tasktemplatedtl.crn;
          this.taskResourceName = tasktemplatedtl.crn;
          this.getTaskTemplateDetail(tasktemplatedtl.crn);
          _.each(fieldvalue, (tk) => {
            if(tk)
            this.selectedTaskResourceId.push(tk.crn + "/" + tk.resourceid);
          });
          this.selectedTaskResourceId = [...this.selectedTaskResourceId];
        }
      }
    });
  }
  getTemplateAssets() {
    this.selectedFields = [];
    let columns = [];
    _.map(this.filteredColumns, (itm: any) => {
      columns.push(
        _.pick(itm, [
          "fieldkey",
          "fieldname",
          "fieldtype",
          "assettype",
          "linkid",
          "isSelected",
          "referencekey",
          "ordernumber",
          "resourcetype",
          "operationtype",
          "crn"
        ])
      );
      if (itm.isSelected) {
        this.selectedFields.push(
          _.pick(itm, [
            "fieldkey",
            "fieldname",
            "fieldtype",
            "assettype",
            "linkid",
            "isSelected",
            "referencekey",
            "ordernumber",
            "resourcetype",
            "operationtype",
            "crn"
          ])
        );
        return itm;
      }
    });
    let resource = {};
    resource[this.editResourceId] = true;
    _.each(this.selectedTaskResourceId, (rs) => {
      resource[rs] = true;
    });
    let filter = { resource };
    let f = {
      tenantid: this.localStorageService.getItem(AppConstant.LOCALSTORAGE.USER)[
        "tenantid"
      ],
      crn: this.selectedResourceName,
      fields: columns,
      // columns : columns,
      filters: filter,
    };
    f["status"] = AppConstant.STATUS.ACTIVE;
    this.assetRecordService.getResourceAssets(f).subscribe((r) => {
      let response: { count: number; rows: Record<string, any>[] } = JSON.parse(
        r._body
      );
    });
  }
  notifyNewTaskEntry(data) {
    data.position = this.taskstemplatesList.length + 1;
    data.expected_result = "Ok / NOK";
    data.orchestrator_id = 0;
    data.workflow_id = 0;
    data["createdby"] = this.userstoragedata.fullname;
    data["createddt"] = new Date();
    data["updatedby"] = this.userstoragedata.fullname;
    data["updateddt"] = new Date();
    this.taskstemplatesList.push(data);
    this.hideModal();
  }
  showModal(obj): void {
    this.tasktemplateObj = obj ? obj : {};
    this.formTitle = "Add Task";
    this.isVisible = true;
    // this.buttonText = this.buttonText;
  }
  hideModal(): void {
    this.tasktemplateObj = {};
    this.formTitle = "Add Task";
    this.isVisible = false;
    // this.buttonText = this.buttonText;
  }
  changeTaskResourceType(details) {
    if (details) {
      // this.getTaskResourceDetails();
      // this.selectedTaskResourceId = [];
      // this.selectedTaskResourceName=null;
      this.taskResourceName = details;
      this.getTaskTemplateDetail(details);
    }
    console.log(details);
  }
  getTaskResourceDetails(){
    this.assetRecordService
    .getResource(
      this.localStorageService.getItem(AppConstant.LOCALSTORAGE.USER)[
      "tenantid"
      ],
      this.selectedTaskResourceName
    )
    .subscribe((d) => {
      let response: IAssetHdr[] = JSON.parse(d._body);
      console.log("selected task resource details:",response);
    });
  }
  getTaskTemplateDetail(crn: string) {
    this.selectedTaskResource = [];
    this.filteredColumns = [];
    this.assetRecordService
      .getResource(
        this.localStorageService.getItem(AppConstant.LOCALSTORAGE.USER)[
        "tenantid"
        ],
        crn
      )
      .subscribe((d) => {
        let response: IAssetHdr[] = JSON.parse(d._body);
        this.selectedTaskResource = response;
        // this.filteredColumns = [];
        // _.each(response, (itm: any, idx: number) => {
        //   itm.isSelected = itm.showbydefault ? true : false;
        //   if (itm.fieldtype != "Reference Asset") {
        //     this.filteredColumns.push(itm);
        //   }
        //   if (idx + 1 == response.length) {
        //     this.resource[crn] = {
        //       filtered: this.filteredColumns,
        //       selected: response,
        //     };
        //     // this.getAssets();
        //   }
        // });
        // this.loadReferences();
        // this.selectedResourceName = crn;
      });
  }
  formattasksTemplate(response) {
    console.log(this.taskResourceFormattedHeader);
  }
  getResourceDetail(crn: string) {
    this.selectedResource = [];
    this.filteredColumns = [];
    this.loading = true;
    this.assetRecordService
      .getResource(
        this.localStorageService.getItem(AppConstant.LOCALSTORAGE.USER)[
        "tenantid"
        ],
        crn
      )
      .subscribe((d) => {
        this.loading = false;
        let response: IAssetHdr[] = JSON.parse(d._body);
        this.selectedResource = response;
        this.filteredColumns = [];
        _.each(response, (itm: any, idx: number) => {
          itm.isSelected = true;
          if (itm.fieldtype != "Reference Asset") {
            this.filteredColumns.push(itm);
          }
          if (idx + 1 == response.length) {
            this.resource[crn] = {
              filtered: this.filteredColumns,
              selected: response,
            };
            // this.getAssets();
          }
        });
        this.loadReferences();
        // this.selectedResourceName = crn;
      });
  }
  getAllCRNs(crns) {
    this.addformloading = true;
    let reqObj = {
      crns: crns,
      tenantid: this.userstoragedata.tenantid,
      status: "Active",
    };
    this.loading = true;
    this.assetRecordService.getAllDetail(reqObj).subscribe((res) => {
      this.loading = false;
      const response = JSON.parse(res._body);
      if (response.status) {
        this.crnData = response.data;
        setTimeout(() => {
          this.formatData();
        }, 100);
      } else {
        setTimeout(() => {
          this.formatData();
        }, 100);
      }
    });
  }
  formatData() {
    this.recordData.forEach((o, idx) => {
      let obj: any = _.clone(o);
      if (obj.fieldtype == "Text") {
        obj["fieldvalue"] = "";
      }
      if (obj.fieldtype == "Select" || obj.fieldtype == "STATUS") {
        obj["fieldvalue"] = "";
        if((obj.operationtype == this.cmdb_operationtype[1] || obj.operationtype == this.cmdb_operationtype[2])
        && (obj.fieldname == this.workpack_defaultAttr[3].fieldname || obj.fieldname == this.workpack_defaultAttr[0].fieldname)){
          obj["defaultval"]="";
        }
        else{
          obj["defaultValues"] =
          obj["defaultval"] != null && obj["defaultval"] != ""
            ? obj["defaultval"].split(",")
            : ["Unknown"];
        }
      } else if (obj.fieldtype == "REFERENCE") {
        obj["defaultValues"] = [];
        obj["fieldvalue"] = null;
        _.map(this.crnData, (item) => {
          if (item.crn == obj.relation) {
            obj["defaultValues"].push({
              name: item.fieldvalue,
              crn: item.crn,
              resourceid: Number(item.resourceid.split("/")[1]),
            });
          }
          return item;
        });
      } else if (obj.fieldtype == "DOCUMENT") {
        try {
          if (typeof obj.fieldvalue == "string") {
            obj.fieldvalue = JSON.parse(obj.fieldvalue);
          }
        } catch (error) { }
      } else {
        obj["fieldvalue"] = "";
      }
      this.addformloading = false;
      this.resourceData[idx] = obj;
      if (this.crndetails) {
        if(this.crndetails.length > 0){
          if(this.crndetails[0]["dtl_operationtype"] == this.workpack_operationtype[1]){ // check published
            this.isPublished=true;
          }
        }
        let selectedkeyvalue: any = _.find(this.crndetails,(cd)=>{
          return cd.fieldkey == this.resourceData[idx].fieldkey
        });
        if (selectedkeyvalue && selectedkeyvalue.resourceid) {
          this.resourceData[idx].resourceid = selectedkeyvalue.resourceid;
        }
        if (selectedkeyvalue && selectedkeyvalue.id) {
          this.resourceData[idx].id = selectedkeyvalue.id;
        }
        if (this.resourceData[idx].fieldtype == "REFERENCE") {
          this.resourceData[idx].fieldvalue = [];
          if (
            selectedkeyvalue &&
            selectedkeyvalue.fieldvalue != "" &&
            selectedkeyvalue.fieldvalue != null
          ) {
            let fieldvalue = JSON.parse(selectedkeyvalue.fieldvalue);
            _.map(fieldvalue, (item) => {
              let mappedData = _.find(
                this.resourceData[idx].defaultValues,
                (itm) => {
                  if (itm.name == item.name) {
                    return itm;
                  }
                }
              );
              this.resourceData[idx].fieldvalue.push(mappedData);
              this.resourceData[idx].fieldvalue = [
                ...this.resourceData[idx].fieldvalue,
              ];
            });
          }
        }
        else if(this.resourceData[idx].fieldtype == "Date"){
          this.resourceData[idx].fieldvalue = "";
          if(selectedkeyvalue){
            if(selectedkeyvalue.fieldvalue != "Invalid Date" && selectedkeyvalue.fieldvalue != "Invalid date" && selectedkeyvalue.fieldvalue != undefined){
              this.resourceData[idx].fieldvalue = selectedkeyvalue.fieldvalue
            }
          }
        } else {
          this.resourceData[idx].fieldvalue = selectedkeyvalue
            ? selectedkeyvalue.fieldvalue
            : "";
        }
        if(selectedkeyvalue){
          this.resourceData[idx].dtl_operationtype = selectedkeyvalue.dtl_operationtype ? selectedkeyvalue.dtl_operationtype : this.workpack_operationtype[0];
        }
      }
      if(this.resourceData[idx].fieldname == this.workpack_defaultAttr[0].fieldname){
        if( this.userstoragedata.userid == this.resourceData[idx].fieldvalue) 
        this.isExcecutorLogin=true;
      }
      if(this.resourceData[idx].fieldname == this.workpack_defaultAttr[4].fieldname && this.userstoragedata.userid == this.resourceData[idx].fieldvalue){
        this.isReviewerLogin=true;
      }
      
      this.resourceData = [...this.resourceData];
      return obj;
    });
//find operationtype
if(this.resourceData.length > 0){
  let idx = _.findIndex(this.executionStatusList[0],(s)=>{
    return this.resourceData[0].dtl_operationtype == s;
  });
  if(idx > -1 && this.isExecutableVersion){
    this.executionStatus=this.executionStatusList[idx];
  }
  this.workpack_status=this.resourceData[0].dtl_operationtype ? this.resourceData[0].dtl_operationtype : this.workpack_operationtype[0];
}
// find isExecutableVersion
// _.each(this.txnrefList,(f)=>{
//   if(f.notes == "1"){
//     this.isExecutableVersion = true;
//   }
//   else{
//     this.isExecutableVersion = false;
//   }
// });
if(this.resourceData.length > 0){
  this.executionStatus = this.resourceData[0].dtl_operationtype;
  this.workpack_status=this.resourceData[0].dtl_operationtype ? this.resourceData[0].dtl_operationtype : this.workpack_operationtype[0];
}

    this.resourceData=[..._.orderBy(this.resourceData, ["ordernumber","id","asc"])];
    this.recordData = [..._.orderBy(this.recordData, ["ordernumber","id","asc"])];
    this.assignWorkflow(); //load workflow data form
  }
  changePosition(rowData, mode) {
    if (mode == "up") {
    }
    if (mode == "up") {
    }
  }
  rightbarChanged(event) {
    this.isVisible = false;
  }
  close() {
    if(this.srcfrom == "executionlist"){
      this.router.navigate([`/workflow-executionlist`]);
    }
    else{
      this.router.navigate([`/workpackmanager`],{ queryParams: { modelcrn: this.selectedResourceName } });
    
    }
    
  }
  openAddView() {
    this.resourceDetailMenuVisible = true;
    // this.resourceDetails = {};
    // this.referringAssetDetails = [];
    this.isAddForm = true;
  }


  editForm(value) {
    let data = { ...value };
    data.publishyn = value.publishyn == "Y" ? true : false;
    this.templateForm.patchValue(data);
  }

  disabledDate = (current) => {
    // Can not select days before today
    return moment().add(-1, "days") >= current;
  };

  initForm() {
    this.templateForm = this.fb.group({
      title: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(500),
        ]),
      ],
      description: [""],
      component: [null],
      category: [null],
      version: [null],
      customerid: [null],
      reference: [null],
      prerequisites: [null],
      notes: [""],
      estimate: [0],
      status: [AppConstant.STATUS.ACTIVE],
    });
  }
  addRecordDetail() {
    let assetdetails = [];
    // this.blockUI = true;
    const resourceTimeStamp = Date.now().toString();
    this.resourceData = _.filter(this.resourceData, (itm) => {
      if (
        itm.fieldtype != "Reference Asset" &&
        itm.fieldtype != "Reference Tag"
      ) {
        return itm;
      }
    });
    if (
      !this.resourceData[0].fieldvalue &&
      this.resourceData[0].fieldtype != "AUTOGEN"
    ) {
      this.message.error(
        "Please enter " + this.resourceData[0].fieldname + " value"
      );
      // this.blockUI = false;
      return false;
    }
    if (
      this.resourceData.length > 1 &&
      !this.resourceData[1].fieldvalue &&
      this.resourceData[0].fieldtype != "AUTOGEN"
    ) {
      this.message.error(
        "Please enter " + this.resourceData[1].fieldname + " value"
      );
      // this.blockUI = false;
      return false;
    }
    let self = this;
    let txnDetails=[];
    _.map(this.resourceData, (item) => {
      let txn={
        txnid : item.id,
        txn: item.resourceid,
        reference:item.crn,
        refkey : item.fieldname,
        notes: false,
        status: "Active",
        createdby: this.userstoragedata.fullname,
        createddt: new Date(),
      }
      if(item.fieldname == this.workpack_defaultAttr[4].fieldname || item.fieldname == this.workpack_defaultAttr[0].fieldname){
        if (item.fieldvalue != undefined && item.fieldvalue != "") {
          txn.notes=true;
        }
        txnDetails.push(txn);
      }
      if (item.fieldvalue != undefined) {
        let obj: any = {
          crn: item.crn,
          fieldkey: item.fieldkey,
          fieldtype: item.fieldtype,
          hdrid: item.id,
          fieldvalue: item.fieldvalue,
          resourceid: this.resourceId
            ? this.resourceId
            : (item.crn + "/" + resourceTimeStamp).toString(),
          // id: self.edit ? item.id : undefined,
          status: "Active",
          createdby: this.userstoragedata.fullname,
          createddt: new Date(),
          lastupdatedby: this.userstoragedata.fullname,
          lastupdateddt: new Date(),
          tenantid: this.userstoragedata.tenantid,
        };
        if (self.isEdit) {
          obj.id = item.id;
        }
        if (item.fieldtype == "REFERENCE" || item.fieldtype == "DOCUMENT") {
          obj.fieldvalue = _.isArray(obj.fieldvalue)
            ? JSON.stringify(obj.fieldvalue)
            : "";
        }
        if (item.fieldtype == "Date") {
          obj.fieldvalue = (obj.fieldvalue && obj.fieldvalue != "" && obj.fieldvalue !=null && obj.fieldvalue != undefined )
           ? moment(obj.fieldvalue).format("DD/MMM/YY") : "";
        }
        if (item.fieldtype == "DateTime") {
          obj.fieldvalue = (obj.fieldvalue && obj.fieldvalue != "" && obj.fieldvalue !=null && obj.fieldvalue != undefined )
           ? moment(obj.fieldvalue).format("DD/MMM/YY h:mm A") : "";
        }
        assetdetails.push(obj);
      }
      return item;
    });
    // task resource details mapping with txnref
    let taskDetails:any[]=_.map(this.selectedTaskDetails,(d)=>{
      d.refkey = this.resourceId;
      d.notes = this.selectedResourceName;
      return d;
    })
    let reqObj = {
      assetdetails: assetdetails,
      txnDetails:txnDetails,
      updatedAssets:[],
      taskDetails : taskDetails
    };
    reqObj.assetdetails=_.map(reqObj.assetdetails,(dt)=>{
      dt.dtl_operationtype =this.workpack_status; 
      if(this.executionStatus && this.isExecutableVersion){
        dt.dtl_operationtype = this.executionStatus;//this.executionStatusList[0];    
      }
      return dt;
    })
    if (this.isEdit){
      let updatedAssets=[];
      _.each(this.org_crndetails,(org)=>{
          let current=_.find(assetdetails,(c:any)=>{
            return(org.id == c.id)
          });
          if(current){
              if(org.fieldvalue != current.fieldvalue){
                updatedAssets.push(current);
              }
          }
      });
      reqObj.updatedAssets = updatedAssets;
    }
    if (this.isEdit) {
      reqObj.taskDetails=_.map(reqObj.taskDetails,(d)=>{
        d.refkey = this.resourceId;
        d.status = "Active";
        d.lastupdatedby = this.userstoragedata["fullname"];
        d.lastupdateddt = new Date();
        return d;
      });
      this.saveloading=true;
      this.assetRecordService.bulkupdateDetail(reqObj,"isWorkflowNotification=true").subscribe(
        (res) => {
          this.saveloading=false;
          // this.blockUI = false;
          if(this.workpack_status == this.workpack_operationtype[1]){
            this.isPublished = true;
          }
          else{
            this.isPublished = false;
          }
          const response = JSON.parse(res._body);
          if (response.status) {
            this.message.success(response.message);
            this.isAddForm = false;
            this.loading = false;
            if(this.ExecuteTemplate){
              this.notifyUpdateEntry.next(response.data);
            }
            // this.router.navigate([`/workpackmanager`]);
          } else {
            this.loading = false;
            this.message.error(response.message);
          }
        },
        (err) => {
          // this.blockUI = false;
          this.loading = false;
          this.message.success("Unable to add recrod.");
        }
      );
    } else {
      reqObj.taskDetails=_.map(reqObj.taskDetails,(d)=>{
        d.dtl_operationtype = this.cmdb_operationtype[3];
        d.refkey = this.resourceId;
        d.status = "Active";
        d.createdby = this.userstoragedata["fullname"];
        d.createddt = new Date();
        return d;
      })
      this.saveloading=true;
      this.assetRecordService.bulkcreateDetail(reqObj).subscribe(
        (res) => {
          this.saveloading=false;
          // this.blockUI = false;

          const response = JSON.parse(res._body);
          if (response.status) {
            this.message.success(response.message);
            this.isAddForm = false;
            this.loading = false;
            this.router.navigate([`/workpackmanager`],{ queryParams: { modelcrn: this.selectedResourceName } });
          } else {
            this.loading = false;
            this.message.error(response.message);
          }
        },
        (err) => {
          // this.blockUI = false;
          this.loading = false;
          this.message.success("Unable to add recrod.");
        }
      );
    }
  }
  notifyNewEntry(data: any[]) {
    this.resourceDetailMenuVisible = false;
    if (data) {
      if (data.length > 0) {
        if (data[0]["resourceid"]) {
          let nameField: any;
          _.map(data, (itm: any) => {
            if (itm.fieldkey.includes("fk:name")) {
              nameField = itm;
              return itm;
            } else if (itm.fieldkey.includes("fk:key")) {
              nameField = itm;
              return itm;
            } else {
              return itm;
            }
          });
          let processData = {
            name: nameField.fieldvalue,
            crn: nameField.crn,
            resourceid: Number(nameField.resourceid.split("/")[1]),
            mode: AppConstant.WORKPACK_CONFIG.WP_TASK_KEY,
          };
          let resourceToMapIndex = _.findIndex(
            this.resourceData,
            (itm: any) => {
              if (
                itm.fieldtype == "REFERENCE" &&
                itm.relation == nameField.crn
              ) {
                return itm;
              }
            }
          );
          this.resourceData[resourceToMapIndex].defaultValues=_.map(this.resourceData[resourceToMapIndex].defaultValues,(d)=>{
            d.mode = AppConstant.WORKPACK_CONFIG.WP_TASK_KEY;
            return d;
          });
          this.resourceData[resourceToMapIndex].defaultValues.push(processData);
          if (
            this.resourceData[resourceToMapIndex].fieldvalue != undefined &&
            this.resourceData[resourceToMapIndex].fieldvalue != ""
          ) {
            this.resourceData[resourceToMapIndex].fieldvalue.push(processData);
          } else {
            this.resourceData[resourceToMapIndex].fieldvalue = [];
            this.resourceData[resourceToMapIndex].fieldvalue[0] = processData;
          }
          this.resourceData[resourceToMapIndex].fieldvalue = [
            ...this.resourceData[resourceToMapIndex].fieldvalue,
          ];
          this.selectedTaskResourceId.push(data[0]["resourceid"]);
          this.selectedTaskResourceId = [...this.selectedTaskResourceId];
        }
      }
    }
  }
  notifyTaskEntry(data: any[]){
    console.log(data);
    this.resourceDetailMenuVisible = false;
    if (data) {
      if (data.length > 0) {
        if (data[0]["resourceid"]) {
          let transaction={
            txn : data[0]["resourceid"],
            reference : data[0]["crn"],
            notes : this.selectedResourceName,
            module : 'workpack-task'
          }
          this.selectedTaskDetails.push(transaction);
          this.selectedTaskResourceId.push(data[0]["resourceid"]);
          this.selectedTaskResourceName=data[0]["crn"];
          this.selectedTaskResourceId = [...this.selectedTaskResourceId];
        }
      }
    }
    // this.selectedTaskDetails
  }
  getCustomerList() {
    this.tenantsService
      .allcustomers({
        tenantid: this.userstoragedata.tenantid,
        status: AppConstant.STATUS.ACTIVE,
      })
      .subscribe((res) => {
        const response = JSON.parse(res._body);
        if (response.status) {
          this.customerList = response.data;
        } else {
          this.customerList = [];
        }
      });
  }

  getCategoryList() {
    let condition = {} as any;
    condition = {
      lookupkey: AppConstant.LOOKUPKEY.TICKET_CATEGORY,
      status: AppConstant.STATUS.ACTIVE,
      tenantid: -1,
    };
    this.commonService.allLookupValues(condition).subscribe((res) => {
      const response = JSON.parse(res._body);
      if (response.status) {
        this.categoryList = response.data;
      } else {
        this.categoryList = [];
      }
    });
  }
  getSubCategoryList() {
    let condition = {} as any;
    condition = {
      lookupkey: AppConstant.LOOKUPKEY.TICKET_SUBCATEGORY,
      status: AppConstant.STATUS.ACTIVE,
      tenantid: -1,
    };
    this.commonService.allLookupValues(condition).subscribe((res) => {
      const response = JSON.parse(res._body);
      if (response.status) {
        this.subcategoryList = response.data;
      } else {
        this.subcategoryList = [];
      }
    });
  }

  onCategoryChange(event) {
    console.log(event);
    if (event != "") {
      let existObj = _.find(this.categoryList, { keyvalue: event });
      if (existObj == undefined) {
        console.log("Value not available");
        this.addCategory = true;
      } else {
        this.addCategory = false;
      }
    }
  }
  onFile(event, data) {
    const reader = new FileReader();
    let attachmentFile = event.target.files[0];
    this.addDocs(attachmentFile, data);
    reader.onload = (e) => {
      // this.attachmentFileImage = e.target["result"];
    };
    reader.readAsDataURL(event.target.files[0]);
  }
  addDocs(attachmentFile, rowdata) {
    const formdata = new FormData();
    let data = {} as any;
    data.tenantid = this.userstoragedata.tenantid;
    data.lastupdateddt = new Date();
    data.lastupdatedby = this.userstoragedata.fullname;
    data.status = "Active";
    if (attachmentFile != undefined && attachmentFile != null) {
      formdata.append("file", attachmentFile);
    } else {
      this.message.error("Please upload file");
      return false;
    }
    data.createdby = this.userstoragedata["fullname"];
    data.createddt = new Date();
    data.crn = rowdata.crn;
    data.mode = AppConstant.WORKPACK_CONFIG.DOCUMENT_DATATYPE;
    // data.resourceid = rowdata.id;
    formdata.append("formData", JSON.stringify(data));
    this.assetRecordService.createDocs(formdata).subscribe((res) => {
      const response = JSON.parse(res._body);
      if (response.status) {
        let emptydata = [];
        emptydata.push(JSON.parse(response.data.meta));
        rowdata.fieldvalue = emptydata;
        // try {
        //   let parse = rowdata.fieldvalue;
        //   if (Array.isArray(parse)) {
        //     parse.push(JSON.parse(response.data.meta));
        //   } else {
        //     parse = [];
        //     parse.push(JSON.parse(response.data.meta));
        //   }
        //   rowdata.fieldvalue = parse;
        // } catch (error) {
        //   let emptydata = [];
        //   emptydata.push(JSON.parse(response.data.meta));
        //   rowdata.fieldvalue = emptydata;
        // }
      } else {
        this.message.error(response.message);
      }
    });
  }
  displayPreviewList(rowdata): any[] {
    let returnValue: any[] = [];
    try {
      let parse = rowdata.fieldvalue;
      try {
        if (typeof rowdata.fieldvalue == "string") {
          parse = JSON.parse(rowdata.fieldvalue);
        }
      } catch (error) {
        parse = rowdata.fieldvalue;
      }
      if (Array.isArray(parse)) {
        _.each(parse, (data, k) => {
          if (_.isObject(data)) {
            returnValue.push(data);
          }
        });
      }
      return returnValue;
    } catch (error) {
      return [];
    }
  }
  removeImage(rawData, idx) {
    let parse = rawData.fieldvalue;
    try {
      if (typeof rawData.fieldvalue == "string") {
        parse = JSON.parse(rawData.fieldvalue);
      }
      if (Array.isArray(parse)) {
        parse.splice(idx, 1);
        rawData.fieldvalue = parse;
        rawData.fieldvalue = [...rawData.fieldvalue];
      }
    } catch (error) {
    }
  }
  downloadFile(rowdata, d) {
    console.log(rowdata);
    if (rowdata.key) {


      this.assetRecordService
        .downloadDocs({
          key: rowdata.key,
        })
        .subscribe((result) => {
          let response = JSON.parse(result._body);
          var buffer = Buffer.from(response.data.content.data);
          const TYPED_ARRAY = new Uint8Array(buffer);
          const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
            return data + String.fromCharCode(byte);
          }, "");
          downloadService(buffer, response.data.filename);
        });
    }
  }
  notifyTaskEditEntry(data: any) {
    // this.resourceDetailMenuVisible = true;
    this.selectedTaskEdit = null;
    this.selectedTaskEdit = data;
    this.isAddForm = false;
    this.viewResourceDetail(data);
    this.selectedTaskResourceID=null;
    // this.selectedTaskEdit=[...this.selectedTaskEdit];
  }
  viewResourceDetail(data: Record<string, string>) {
    this.assetRecordService
      .getResourceValuesById(btoa(data["resource"]), `tagdetails=${true}`) // <<crn>>/<<resourceid>>
      .subscribe(
        (r) => {
          let response: {
            data: IAssetDtl[];
            inbound: Record<string, any>[];
            documents: Record<string, any>[];
            referringassets: Record<string, any>[];
          } = JSON.parse(r._body);

          let data: any = {
            details: [],
          };
          response.data.forEach((o) => {
            data[o["fieldkey"]] = o["fieldvalue"];
            data["header"] = this.selectedResource;
            data["details"].push(o);
          });
          this.resourceDetails = data;
          if(data.details){
            this.selectedTaskResourceID = data.details[0].resourceid;
          }
          // this.inboudResourceDetails = response.inbound;
          this.referringAssetDetails = response.referringassets;
          this.resourceDetailMenuVisible = true;
        },
        (err) => {
          alert("Unable to fetch details. Try again.");
        }
      );
  }
  IsDecimalExist(pecimalNumber) {
    var l_boolIsExist = true;

    if (pecimalNumber % 1 == 0)
        l_boolIsExist = false;

    return l_boolIsExist;
}
onChanged(event){
  this.selectedWorkflowTask = null;
  this.isworkflowVisible=false;
}
assignWorkflow(){
  if(this.resourceId){
    this.selectedWorkflowTask = this.resourceId;
    this.resoruceTitle="";
    this.isworkflowVisible=true;
  }
}
notifyTaskSelection(event:any){
  if(event){
    this.selectedWorkpackTitle = event.title;
    this.selectedTaskResourceName=event.crn;
    this.changeTaskResourceType(event.crn);
  }
  this.isTreeVisible=false;
}
notifyWorkflowUpfateEntry(event){
  this.isworkflowVisible=false;
  setTimeout(() => {
    this.assignWorkflow();
  }, 1000);
}
downloadPDF(){
  // this.resourceData
  // this.selectedField
  let data :any = {};
  _.each(this.resourceData,(r:any)=>{
    data[r.fieldkey]=r.fieldvalue;
  });
console.log(this.resourceData);
console.log(this.selectedFields);
    // // working
    let formattedData=_.map(this.selectedFields,(f:any)=>{
      f.fieldvalue = "";
      if(data[f.fieldname]){
        f.fieldvalue = data[f.fieldname];
      }
      return f;
    });
    console.log(formattedData);
    // this.downloadableDiv.nativeElement.innerHTML ="";
    // this.download_loading=true;
    // let tasksFormattedData = await this.workpackService.getAssetDetails(data.resource);
    // // download api call
    // this.workpackService
    // .download(
    //   {
    //     formattedData : formattedData,
    //     resourceid: data.resource,
    //     tasksFormattedData: tasksFormattedData
    //   }
    // )
    // .subscribe((result) => {
    //   this.download_loading=false;
    //   if (result.status) {
    //     let headerData= _.find( formattedData,(f)=>{
    //       let fieldname : string=f.fieldname;
    //     return((fieldname.toLowerCase() == "name" || fieldname.toLowerCase() == "Title" ||
    //      fieldname.toLowerCase() == "script id"))
    // });
    // let filename="workpack" +"_" + moment().format("DD-MM-YYYY") +".pdf";
    // if(headerData){
    //   let fnameraw=headerData.resourcetype+"-"+headerData.fieldvalue;
    //   fnameraw = fnameraw.replace(/[\s~`!@#$%^&*()_+\-={[}\]|\\:;"'<,>.?/]+/g, '_');
    //   fnameraw = fnameraw.substring(0, 50);
    //   filename = fnameraw +"_" + moment().format("DD-MM-YYYY") +".pdf";
    // }
    //     let response = JSON.parse(result._body);
    //     if (response.data) {
    //       var buffer = Buffer.from(response.data.content.data);
    //       downloadService(
    //         buffer,filename
    //       );
    //     }
    //   }

    // });

    
}
addEditWatchList(event){
  setTimeout(() => {
    console.log(event);
    let list=[];
    _.each(this.watchList,(w)=>{
      list.push({
        refkey: this.resourceId,
        txn:w,
        module :this.cmdb_operationtype[7],
        // module : 'workpack-task',
        status : AppConstant.STATUS.ACTIVE,
        createdby : this.userstoragedata.fullname,
        createddt : new Date(),
        updatedby : this.userstoragedata.fullname,
        updateddt : new Date()
      });
    });
    let req={
      refkey: this.resourceId,
      module :this.cmdb_operationtype[7],
      list: list
    }
    this.assetRecordService
      .updateWatchList(
        req
      )
      .subscribe((taskstxndata) => {
        console.log(taskstxndata);
      });
  }, 100);
}
refresh() {
  this.editDetails(this.selectedResourceID);
}
}
