import { Component, OnInit, SimpleChanges, Input } from "@angular/core";
@Component({
  selector: "app-cloudmatiq-servicemgmt",
  templateUrl:
    "../../../presentation/web/tenant/servicemgmt/sla.component.html"
})
export class SLAComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
