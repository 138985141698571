import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AssetsService } from "../assets/assets.service";
import { LocalStorageService } from "src/app/modules/services/shared/local-storage.service";
import { AppConstant } from "src/app/app.constant";
import * as _ from "lodash";

@Component({
  selector: "app-product",
  templateUrl: "../../base/product/product.component.html",
})
export class productComponent implements OnInit {
  totalCount = null;
  tableHeader = [
    {
      field: "productcode",
      header: "Product Code",
      datatype: "string",
    },
    {
      field: "productname",
      header: "Product Name",
      datatype: "string",
    },
    {
      field: "servertype",
      header: "Server Type",
      datatype: "string",
    },
    {
      field: "lastupdatedby",
      header: "Updated By",
      datatype: "string",
    },
    {
      field: "lastupdateddt",
      header: "Updated date",
      datatype: "timestamp",
      format: "dd-MMM-yyyy hh:mm:ss",
    },
    { field: "status", header: "Status", datatype: "string" },
  ];

  tableconfig = {
    refresh: true,
    edit: false,
    delete: false,
    manualsearch: true,
    globalsearch: true,
    pagination: true,
    manualpagination: false,
    loading: false,
    pageSize: 10,
    apisort: true,
    title: "",
    widthConfig: ["25px", "25px", "25px", "25px", "25px"],
  };
  productObj: any = {};
  globalSearchModel = "";
  limit = 10;
  offset = 0;
  createProduct = false;
  searchText = null;
  productList = [];
  order = ["lastupdateddt", "desc"];
  userstoragedata: any;
  screens: any;
  formTitle = "Add Product";
  isVisible = false;
  appScreens = {} as any;

  constructor(
    private assetService: AssetsService,
    private localStorageService: LocalStorageService
  ) {
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
    this.screens = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.SCREENS
    );
    this.appScreens = _.find(this.screens, {
      screencode: AppConstant.SCREENCODES.PRODUCTS,
    });
    if (_.includes(this.appScreens.actions, "Create")) {
      this.createProduct = true;
    }
    if (_.includes(this.appScreens.actions, "Edit")) {
      this.tableconfig.edit = true;
    }
    if (_.includes(this.appScreens.actions, "Delete")) {
      this.tableconfig.delete = true;
    }
  }

  ngOnInit() {
    this.getAllProductList();
  }

  getAllProductList(limit?, offset?) {
    this.productList = [];
    this.tableconfig.loading = true;

    let reqObj: any = {
      tenantid: this.userstoragedata.tenantid,
    };
    this.assetService.listProducts(reqObj).subscribe((res) => {
      const response = JSON.parse(res._body);
      if (response.status) {
        this.productList = response.data;
        this.totalCount = this.productList.length;
        this.tableconfig.loading = false;
      } else {
        this.tableconfig.loading = false;
        this.productList = [];
      }
    });
  }

  dataChanged(e) {
    if (e.searchText != "" && e.search) {
      this.searchText = e.searchText;
      this.getAllProductList(10, 0);
    }

    if (e.searchText == "") {
      this.searchText = null;
      this.getAllProductList(10, 0);
    }

    if (e.refresh) {
      this.getAllProductList();
    }

    if (e.order) {
      this.order = e.order;
      this.getAllProductList(10, 0);
    }

    if (e.edit) {
      this.isVisible = true;
      this.productObj = e.data;
      console.log(this.productObj);
      this.formTitle = "Update Product";
    }
  }

  showAddForm() {
    this.isVisible = true;
    this.formTitle = "Add Product";
    this.productObj = {};
  }

  rightbarChanged(val) {
    this.isVisible = val;
    this.getAllProductList();
  }

  notifyProductEntry() {
    this.getAllProductList();
  }
}
