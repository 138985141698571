import { Component, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { LocalStorageService } from "src/app/modules/services/shared/local-storage.service";
import { AppConstant } from "src/app/app.constant";
import { NzMessageService, NzDropdownContextComponent } from "ng-zorro-antd";
import { NotificationSetupService } from "./notificationsetup.service";
import { Router } from "@angular/router";
import { TreeComponent } from "angular-tree-component";
@Component({
  selector: "app-costsetup",
  templateUrl:
    "../../../presentation/web/tenant/notificationsetup/notificationsetup.component.html",
})
export class NotificationSetupComponent implements OnInit {
  private dropdown: NzDropdownContextComponent;

  screens = [];
  appScreens = {} as any;
  createcost = false;
  isVisible = false;
  showSidebar = false;
  isGroupHierarchyVisible = false;
  loading = true;
  totalCount = null;

  userstoragedata = {} as any;
  folderName = "";

  tableHeader = [
    { field: "module", header: "Module", datatype: "string", width: "13%" },
    { field: "event", header: "Event", datatype: "string", width: "13%" },
    { field: "ntftype", header: "Type", datatype: "string", width: "13%" },
    { field: "notes", header: "Notes", datatype: "string", width: "30%" },
    {
      field: "lastupdatedby",
      header: "Updated By",
      datatype: "string",
      width: "8%",
    },
    {
      field: "lastupdateddt",
      header: "Updated On",
      datatype: "timestamp",
      format: "dd-MMM-yyyy hh:mm:ss",
      width: "12%",
    },
    { field: "status", header: "Status", datatype: "string" },
  ] as any;

  tableConfig = {
    refresh: true, //#OP_B632
    edit: false,
    delete: false,
    globalsearch: true,
    loading: false,
    pagination: true,
    pageSize: 10,
    scroll: { x: "1000px" },
    title: "",
    widthConfig: ["30px", "25px", "25px", "25px", "25px"],
  } as any;
  assetTypes: any = [];
  filters = { asset: null } as any;
  notificationList = [];
  zoneList = [];

  formTitle = "Add Notification";

  notificationObj = {} as any;

  structureId;
  savingStructure = false;

  @ViewChild(TreeComponent)
  private tree: TreeComponent;
  constructor(
    private notificationService: NotificationSetupService,
    public router: Router,
    private message: NzMessageService,
    private localStorageService: LocalStorageService
  ) {
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
    this.screens = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.SCREENS
    );
    this.appScreens = _.find(this.screens, {
      screencode: AppConstant.SCREENCODES.NOTIFICATION_SETUP,
    });
    if (_.includes(this.appScreens.actions, "Create")) {
      this.createcost = true;
    }
    if (_.includes(this.appScreens.actions, "Edit")) {
      this.tableConfig.edit = true;
    }
    if (_.includes(this.appScreens.actions, "Delete")) {
      this.tableConfig.delete = true;
    }
    this.getAllList();
  }

  ngOnInit() {}

  dataChanged(d) {
    if (d.edit || d.revise) {
      this.formTitle = d.edit ? "Update Notification" : "Revise Notification";
      this.notificationObj = d;
      this.showSidebar = true;
    }
    if (d.delete) {
      this.notificationObj = d.data;
      this.deleteNotification();
    }
    //#OP_B632
    if (d.refresh) {
      this.getAllList();
    }
  }

  showAddForm() {
    this.showSidebar = true;
    this.formTitle = "Add Notification";
    this.notificationObj = {};
  }

  notifyTagEntry(val: boolean) {
    if (val) {
      this.showSidebar = false;
      this.getAllList();
    }
  }
  deleteNotification() {
    this.isVisible = true;
    this.notificationService
      .update({
        ntfcsetupid: this.notificationObj.ntfcsetupid,
        status: AppConstant.STATUS.DELETED,
      })
      .subscribe((result) => {
        let response = JSON.parse(result._body);
        if (response.status) {
          this.getAllList();
          this.message.info("Deleted Succesfully");
          this.isVisible = false;
        } else {
          this.message.info(response.message);
          this.isVisible = false;
        }
      });
  }

  onChanged(val) {
    this.showSidebar = val;
    this.notificationObj = {};
  }
  getAllList() {
    let obj = {
      status: AppConstant.STATUS.ACTIVE,
    } as any;
    this.isVisible = true;
    this.notificationService.all(obj).subscribe(
      (result) => {
        let response = JSON.parse(result._body);
        if (response.status) {
          this.notificationList = response.data;
          this.totalCount = this.notificationList.length;
          this.loading = false;
        } else {
          this.loading = false;
          this.notificationList = [];
        }
      },
      (err) => {
        console.log(err);
        this.isVisible = false;
      }
    );
    this.isVisible = false;
  }

  formArrayData(data, label, value) {
    let array = [] as any;
    data.forEach((element) => {
      let obj = {} as any;
      obj.label = element[label];
      obj.value = element[value];
      array.push(obj);
    });
    return array;
  }
}
