import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { LocalStorageService } from "../../../../modules/services/shared/local-storage.service";
import { NzMessageService } from "ng-zorro-antd";
import { AppConstant } from "../../../../app.constant";
import { CommonService } from "../../../../modules/services/shared/common.service";
import * as _ from "lodash";
import { NotificationConstant } from "../../../../notification.constant";
import { NotificationSetupService } from "../notificationsetup.service";
import { HttpHandlerService } from "src/app/modules/services/http-handler.service";
import { UsersService } from "src/app/business/admin/users/users.service";
@Component({
  selector: "app-notification-setup-add-edit",
  templateUrl:
    "../../../../presentation/web/tenant/notificationsetup/add-edit/notification-add-edit.component.html",
})
export class AddEditNotificationComponent
  implements OnInit, OnChanges, AfterViewInit
{
  disabled = false;
  loading = false;

  @Input() notificationObj: any;
  @Output() notifyTagEntry: EventEmitter<any> = new EventEmitter();

  notificationForm: FormGroup;
  userstoragedata = {} as any;
  moduleList: any = [
    {
      label: "Template",
      value: "Template",
      events: [
        { label: "Created", value: "Created" },
        { label: "Edited", value: "Edited" },
        { label: "Deleted", value: "Deleted" },
      ],
    },
    {
      label: "Deployment",
      value: "Deployment",
      events: [
        { label: "Success", value: "Success" },
        { label: "Failed", value: "Failed" },
      ],
    },
    {
      label: "Asset",
      value: "Asset",
      events: [
        { label: "Resize", value: "Resize" },
        { label: "Data Collection Failure", value: "Data Collection Failure" },
        { label: "New", value: "New" },
        { label: "Modified", value: "Modified" },
        { label: "Deleted", value: "Deleted" },
      ],
    },
    {
      label: "Catalog",
      value: "Catalog",
      events: [
        { label: "Published", value: "Published" },
        { label: "Unpublished", value: "Unpublished" },
        { label: "Changed", value: "Changed" },
      ],
    },
    {
      label: "Service Request",
      value: "Service Request",
      events: [
        { label: "Created", value: "Created" },
        { label: "Approved", value: "Approved" },
        { label: "Rejected", value: "Rejected" },
      ],
    },
    {
      label: "Users",
      value: "Users",
      events: [
        { label: "Created", value: "Created" },
        { label: "Role Change", value: "Role Change" },
        { label: "Deleted", value: "Deleted" },
      ],
    },
    {
      label: "Role",
      value: "Role",
      events: [
        { label: "Created", value: "Created" },
        { label: "Edited", value: "Edited" },
        { label: "Deleted", value: "Deleted" },
      ],
    },
    {
      label: "Budget",
      value: "Budget",
      events: [{ label: "Over run", value: "Over Run" }],
    },
  ];
  eventList: any = [];
  usersList: any = [];
  ntfList: any = [
    { label: "Email", value: "Email" },
    { label: "SMS", value: "SMS" },
    { label: "Application", value: "Application" },
  ];
  buttonText = AppConstant.BUTTONLABELS.SAVE;
  notificationErrObj = AppConstant.VALIDATIONS.NOTIFICATION;

  constructor(
    private message: NzMessageService,
    private fb: FormBuilder,
    private userService: UsersService,
    private httpService: HttpHandlerService,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationSetupService,
    private commonService: CommonService
  ) {
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
    let obj = {
      tenantid: this.userstoragedata.tenantid,
      status: AppConstant.STATUS.ACTIVE,
    };
    this.getUserList();
    this.clearForm();
  }
  ngAfterViewInit(): void {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["notificationObj"]) {
      this.clearForm(this.notificationObj.data);
    }
  }
  clearForm(data?) {
    if (data) {
      this.moduleChanges(data.module);
    }
    this.notificationForm = this.fb.group({
      module: [data ? data.module : null, Validators.required],
      event: [data ? data.event : null, Validators.required],
      notes: [data ? data.notes : null, Validators.required],
      ntftype: [data ? data.ntftype : null, Validators.required],
      template: [data ? data.template : null, Validators.required],
      tenantid: this.userstoragedata.tenantid,
      receivers: [
        data ? JSON.parse(data.receivers) : null,
        Validators.required,
      ],
      status: ["Active"],
      createdby: data ? data.createdby : this.userstoragedata.fullname,
      createddt: data ? data.createddt : new Date(),
    });
  }
  moduleChanges(i) {
    if (i) {
      let obj = _.find(this.moduleList, { value: i }) as any;
      if (obj) {
        this.notificationForm.get("event").setValue(null);
        this.eventList = obj.events;
      }
    }
  }
  eventChanges() {
    let type = this.notificationForm.value.ntftype;
    let mod = this.notificationForm.value.module;
    let event = this.notificationForm.value.event;
    console.log(type, mod, event);
    if (NotificationConstant[type] && NotificationConstant[type][mod]) {
      let value = NotificationConstant[type][mod][event];
      if (value) {
        this.notificationForm.get("template").setValue(value);
      }
    }
  }
  formArrayData(data, label, value) {
    let array = [] as any;
    data.forEach((element) => {
      let obj = {} as any;
      obj.label = element[label];
      obj.value = element[value];
      array.push(obj);
    });
    return array;
  }

  getUserList() {
    this.loading = true;
    this.userService
      .allUsers({
        tenantid: this.userstoragedata.tenantid,
        status: AppConstant.STATUS.ACTIVE,
      })
      .subscribe((res) => {
        const response = JSON.parse(res._body);
        if (response.status) {
          this.usersList = this.formArrayData(
            response.data,
            "fullname",
            "userid"
          );
          this.loading = false;
        } else {
          this.usersList = [];
          this.loading = false;
        }
      });
  }
  saveUpdateScript(data) {
    if (this.notificationForm.status === "INVALID") {
      let errorMessage = "" as any;
      errorMessage = this.commonService.getFormErrorMessage(
        this.notificationForm,
        this.notificationErrObj
      );
      this.message.remove();
      this.message.error(errorMessage);
      return false;
    } else if (data.ntftype == "SMS" && data.template.length > 160) {
      this.message.error("The template should be less than 160 charecters");
    } else {
      data.receivers = JSON.stringify(data.receivers);
      data.lastupdatedby = this.userstoragedata.fullname;
      data.lastupdateddt = new Date();
      if (
        this.notificationObj &&
        this.notificationObj.data &&
        this.notificationObj.data.ntfcsetupid
      )
        data.ntfcsetupid = this.notificationObj.data.ntfcsetupid;
      if (data.ntfcsetupid) {
        this.notificationService.update(data).subscribe((result) => {
          let response = JSON.parse(result._body);
          if (response.status) {
            setTimeout(() => {
              this.message.info("Updated Succesfully");
              this.notifyTagEntry.emit(true);
            }, 1000);
          } else {
            this.message.info(response.message);
          }
        });
      } else {
        data.createdby = this.userstoragedata.fullname;
        data.createddt = new Date();
        this.notificationService.create(data).subscribe((result) => {
          let response = JSON.parse(result._body);
          if (response.status) {
            setTimeout(() => {
              this.message.info("Saved Succesfully");
              this.notifyTagEntry.emit(true);
            }, 1000);
          } else {
            this.message.info(response.message);
          }
        });
      }
    }
  }
}
