import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-keys",
  templateUrl:
    "../../../../presentation/web/deployments/ecl2/keys/keys.component.html",
})
export class KeysComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
