import { Component, OnInit } from "@angular/core";
import { RoleService } from "./role.service";
import { AppConstant } from "../../../app.constant";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CommonService } from "../../../modules/services/shared/common.service";
import { LocalStorageService } from "../../../modules/services/shared/local-storage.service";
import { NzMessageService } from "ng-zorro-antd";
import { Router, ActivatedRoute } from "@angular/router";
import * as _ from "lodash";

@Component({
  selector: "app-role",
  templateUrl: "../../../presentation/web/admin/role/role.component.html",
})
export class RoleComponent implements OnInit {
  loading = true;
  roleListHeaders = [
    { field: "rolename", header: "Role Name", datatype: "string" },
    { field: "lastupdatedby", header: "Updated By", datatype: "string" },
    {
      field: "lastupdateddt",
      header: "Updated On",
      datatype: "timestamp",
      format: "dd-MMM-yyyy hh:mm:ss",
    },
    { field: "status", header: "Status", datatype: "string" },
  ];
  roleTableConfig = {
    refresh: true,
    edit: false,//#OP_T620
    delete: false,//#OP_T620
    globalsearch: true,
    manualsearch: true,
    pagination: false,
    manualpagination: true,
    apisort: true,
    pageSize: 10,
    count: null,
    loading: false,
    clone: false,//#OP_T620
    view: false,//#OP_T620

    title: "",
    widthConfig: ["25px", "25px", "25px", "25px", "25px", "25px", "25px"],
  };
  widthConfig: ["25px", "25px", "25px", "25px", "25px", "25px", "25px"];
  roleList = [];
  originalList = [];
  buttonText = "";
  button = "";
  formdata: any = {};
  userstoragedata: any;
  formTitle = "";
  roleObj: any = {};
  appScreens = {} as any;
  screens = [];
  visibleadd = false;
  rolename: any;
  tableData: any[];
  originalData: any[];
  pageSize: any;
  pageIndex: number;
  currentSortColumn: any;
  totalCount;
  limit = 10;
  offset = 0;
  searchText = null;
  order = ["lastupdateddt", "desc"];

  constructor(
    private roleService: RoleService,
    private router: Router,
    private fb: FormBuilder,
    private commonService: CommonService,
    private message: NzMessageService,
    private localStorageService: LocalStorageService
  ) {
    this.buttonText = "Add Role";
    this.formTitle = AppConstant.VALIDATIONS.USER.ROLES.ADD;
    this.button = AppConstant.VALIDATIONS.SAVE;
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
    this.rolename = this.userstoragedata.roles.rolename;
    this.screens = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.SCREENS
    );

    this.appScreens = _.find(this.screens, {
      screencode: AppConstant.SCREENCODES.ACCESS_MANAGEMENT,
    });

    if (_.includes(this.appScreens.actions, "Create")) {
      this.visibleadd = true;
    }
    //#OP_T620
    if (_.includes(this.appScreens.actions, "View")) {
      this.roleTableConfig.view = true;
    }
    if (_.includes(this.appScreens.actions, "Edit")) {
      this.roleTableConfig.edit = true;
    }
    if (_.includes(this.appScreens.actions, "Delete")) {
      this.roleTableConfig.delete = true;
    }
    if (_.includes(this.appScreens.actions, "Copy")) {
      this.roleTableConfig.clone = true;
    }
  }

  ngOnInit() {
    this.getAllRoles();
  }
  getAllRoles(limit?, offset?) {
    this.loading = true;
    let condition = {} as any;
    condition = {
      status: AppConstant.STATUS.ACTIVE,
    };
    let tenantList = [];
    if (
      this.userstoragedata.roles.rolename !=
      AppConstant.VALIDATIONS.USER.ROLES.ADMIN
    ) {
      tenantList = [this.userstoragedata.tenantid, 0];
    } else {
      tenantList = [this.userstoragedata.tenantid, -1, 0];
    }
    condition.tenantList = tenantList;
    if (this.searchText != null && this.searchText != "") {
      condition["searchText"] = this.searchText;
    }
    if (this.order && this.order != null) {
      condition["order"] = this.order;
    }
    let query = `count=${true}&limit=${limit ? limit : 10}&offset=${
      offset ? offset : 0
    }&order=${this.order ? this.order : ""}`;

    this.roleService.allRoles(condition, query).subscribe((res) => {
      const response = JSON.parse(res._body);
      if (response.status) {
        this.roleTableConfig.manualpagination = true;
        this.roleList = response.data.rows;
        this.originalList = response.data;
        this.totalCount = response.data.count;
        this.roleTableConfig.count = "Total Records"+ ":"+ " "+this.totalCount;
        this.loading = false;
      } else {
        this.roleList = [];
        this.totalCount = 0;
        this.loading = false;
      }
    });
  }
  dataChanged(result) {
    if (result.pagination) {
      this.roleTableConfig.pageSize = result.pagination.limit;
      this.getAllRoles(result.pagination.limit, result.pagination.offset);
    }
    if (result.searchText != "") {
      this.searchText = result.searchText;
      if (result.search) {
        this.getAllRoles(this.roleTableConfig.pageSize, 0);
      }
    }
    if (result.searchText == "") {
      this.searchText = null;
      this.getAllRoles(this.roleTableConfig.pageSize, 0);
    }
    if (result.order) {
      this.order = result.order;
      this.getAllRoles(this.roleTableConfig.pageSize, 0);
    }
    if (result.refresh) {
      this.getAllRoles();
    }
    //#OP_T620
    if (result.edit) {
      this.router.navigate(["role/edit/" + result.data.roleid]);
    }
    if (result.clone) {
      this.router.navigate(["role/copy"], {
        queryParams: { id: result.data.roleid, mode: "Copy" },
      });
    }
    if (result.view) {
      this.router.navigate(["role/view"], {
        queryParams: { id: result.data.roleid, mode: "View" },
      });
    }
    if (result.delete) {
      this.deleteRecord(result.data.roleid);
    }
  }
  deleteRecord(data) {
    let formdata = {} as any;
    formdata = {
      roleid: data.roleid,
      status: "Deleted",
      tenantid: this.userstoragedata.tenantid,
      lastupdatedby: this.userstoragedata.fullname,
      lastupdateddt: new Date(),
    };
    this.roleService.updateRole(formdata).subscribe((result) => {
      let response = {} as any;
      response = JSON.parse(result._body);
      if (response.status) {
        response.data.status === AppConstant.STATUS.DELETED
          ? this.message.success(
              "#" + response.data.roleid + " Deleted Successfully"
            )
          : this.message.success(response.message);
        this.getAllRoles();
      } else {
        this.message.error(response.message);
      }
    });
  }
}
