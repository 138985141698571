import { Component, Input, OnInit } from "@angular/core";
import { AppConstant } from "src/app/app.constant";
import { LocalStorageService } from "src/app/modules/services/shared/local-storage.service";
import { CommonService } from "src/app/modules/services/shared/common.service";
import * as _ from "lodash";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexYAxis,
  ApexTooltip,
  ApexFill,
  ApexLegend
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};

@Component({
  selector: "app-compliance-score",
  templateUrl: "./compliance-score.component.html",
  styleUrls: ["./compliance-score.component.css"],
})
export class ComplianceScoreComponent implements OnInit {
  @Input() isTemplateShowHide = false;
  public chartOptions: Partial<ChartOptions>;
  selectedRequirement: any[] = [];
  loading = false;
  userstoragedata = {} as any;
  isModalVisible = false;
  isVisible = false
  modalTitle = "";
  formTitle = "";
  dashboardURL = null as null | SafeUrl;
  instanceData: any;
  logsList = [];
  modalData = {};
  iframeSrc =
    "https://wazuhdb.opsmaster.ai/app/dashboards#/view/1c7ed300-10ea-11ef-96cf-8bfc28575c01?embed=true&hide-filter-bar=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1d,to:now))";
    
  tableConfig = {
      globalsearch: true,
      apisort: true,
      view: false,
      pagination: true,
      frontpagination: true,
      manualpagination: false,
      pageSize: 10,
      loading: false,
    } as any;
  tableHeader = [
      {
        field: "timestamp",
        header: "Time",
        datatype: "timestamp",
        format: "dd-MMM-yyyy hh:mm:ss",
      },
      {
        field: "name",
        header: "Agent name",
        datatype: "number",
      },
      {
        field: "rule",
        header: "rule.pci_dss",
        datatype: "string",
      },
      {
        field: "description",
        header: "Description",
        datatype: "string",
        isDescription: true
      },
      {
        field: "level",
        header: "Level",
        datatype: "number",
      },
    ] as any;

  complianceReport = AppConstant.COMPLIANCEREPORT
  selectedCompliance: any = null;

  constructor(
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.instanceData = this.commonService.getInstanceDetails();
    console.log(this.instanceData, "instanceData")
  }

  getProgressScore(score: number): string {
    if (score < 30) {
      return 'progress-low';
    } else if (score >= 30 && score < 60) {
      return 'progress-medium';
    } else if (score >= 60 && score < 80) {
      return 'progress-high';
    } else {
      return 'progress-critical';
    }
  }

  onPanelChange(active: boolean, index: any): void {
    if (active) {
      // this.selectedCompliance = compliance;
      // this.selectedRequirement = compliance.controls || [];
      if (!this.selectedRequirement[index]) {
        this.selectedRequirement[index] = [];
      }
    } else {
      this.selectedCompliance = null;
      this.selectedRequirement = [];
    }
  }

  showDashboard(title: string): void {
    this.modalTitle = title;
    this.isModalVisible = true;
    this.dashboardURL = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.iframeSrc
    );
  }
  close() {
    this.router.navigate(["compliance-score"], { queryParams: { list: true } });
  }
  onRequirementClick(e: any, index: string) {
    console.log(e);
    if(e){
      this.selectedRequirement[index] = e.subcontrols
    }else {
      this.selectedRequirement[index]  = [];
    }
  }
  onControlsClick(event: any){
    this.modalData = event;
    if(event.logs.length > 0) {
      this.tableConfig.loading = true; 
      setTimeout(() => {
        this.tableConfig.loading = false;
        this.logsList = event.logs
      }, 1000);
    }else {
      this.logsList = [];
    }
    this.isVisible = true
    this.formTitle = "Requirement description" + " " + " - " + " " + event.id
  }

  dataChanged(result){
    console.log(result);
  }
}
