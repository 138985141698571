import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd";
import * as _ from "lodash";
import { SrmService } from "../../srm.service";
import { AppConstant } from "../../../../app.constant";
import { LocalStorageService } from "../../../../modules/services/shared/local-storage.service";
@Component({
  selector: "app-srm-generic-list",
  templateUrl:
    "../../../../presentation/web/srm/generic-request/list/generic-list.component.html",
})
export class SRMGenericListComponent implements OnInit {
  @Output() notfiyEntry: EventEmitter<any> = new EventEmitter();
  subtenantLable = AppConstant.SUBTENANT;
  genericList = [];
  userstoragedata: any = {};
  searchText: string;
  originalData: any = [];
  widthConfig: ["25px", "25px", "25px", "25px", "25px"];
  sortValue = null;
  sortName = null;
  screens = [];
  appScreens = {} as any;
  addAccess: boolean = false;
  editAccess: boolean = false;
  deleteAccess: boolean = false;
  deployAccess: boolean = false;
  viewAccess: boolean = false;
  progressAccess: boolean = false;
  constructor(
    private router: Router,
    private srmService: SrmService,
    private localStorageService: LocalStorageService,
    private messageService: NzMessageService
  ) {
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
    this.screens = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.SCREENS
    );
    this.appScreens = _.find(this.screens, {
      screencode: AppConstant.SCREENCODES.SERVICE_CATALOG_REQUEST,
    });
    if (_.includes(this.appScreens.actions, "Create")) {
      this.addAccess = true;
    }
    if (_.includes(this.appScreens.actions, "Edit")) {
      this.editAccess = true;
    }
    if (_.includes(this.appScreens.actions, "Delete")) {
      this.deleteAccess = true;
    }
    if (_.includes(this.appScreens.actions, "Edit")) {
      this.deployAccess = true;
    }
    if (_.includes(this.appScreens.actions, "View")) {
      this.viewAccess = true;
    }
    if (_.includes(this.appScreens.actions, "Progress Update")) {
      this.progressAccess = true;
    }
  }

  ngOnInit() {
    this.getSRMGenericList();
  }
  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.search();
  }
  navigate(){
    this.router.navigate(["srm/generic/create"]);
  }
  search(): void {
    const data = this.originalData.filter((item) => item);
    if (this.sortName) {
      // tslint:disable-next-line:max-line-length
      this.genericList = data.sort((a, b) =>
        this.sortValue === "ascend"
          ? a[this.sortName] > b[this.sortName]
            ? 1
            : -1
          : b[this.sortName] > a[this.sortName]
          ? 1
          : -1
      );
    } else {
      this.genericList = data;
    }
  }
  edit(data) {
    if (data) {
      this.router.navigate(["srm/generic/request/" + data.srvrequestid]);
    }
  }
  view(data) {
    if (data) {
      this.router.navigate(["srm/generic/view/" + data.srvrequestid]);
    }
  }
  updateProgress(data) {
    if (data) {
      this.router.navigate(["srm/generic/progress/" + data.srvrequestid]);
    }
  }
  globalSearch(searchText: any) {
    if (searchText !== "" && searchText !== undefined && searchText != null) {
      const self = this;
      this.genericList = [];
      this.originalData.map(function (item) {
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const element = item[key];
            const regxExp = new RegExp("\\b" + searchText, "gi");
            if (regxExp.test(element)) {
              if (!_.some(self.genericList, item)) {
                self.genericList.push(item);
              }
            }
          }
        }
      });
    } else {
      this.genericList = this.originalData;
    }
  }
  getSRMGenericList() {
    this.srmService
      .allService({
        status: "Active",
        tenantid: this.userstoragedata.tenantid,
        requesttype: "Generic",
      })
      .subscribe((result) => {
        this.notfiyEntry.next(false);
        let response = {} as any;
        response = JSON.parse(result._body);
        if (response.status) {
          this.genericList = response.data;
          this.originalData = this.genericList;
        } else {
          this.genericList = [];
          this.originalData = [];
        }
      });
  }

  deleteGenericService(data) {
    let formdata = {} as any;
    formdata = {
      srvrequestid: data.srvrequestid,
      status: "Deleted",
      lastupdatedby: this.userstoragedata.fullname,
      lastupdateddt: new Date(),
    };
    this.srmService.updateService(formdata).subscribe((result) => {
      let response = {} as any;
      response = JSON.parse(result._body);
      if (response.status) {
        response.data.status === AppConstant.STATUS.DELETED
          ? this.messageService.success(
              "#" + response.data.srvrequestid + " Deleted Successfully"
            )
          : this.messageService.success(response.message);
        this.getSRMGenericList();
      } else {
        this.messageService.error(response.message);
      }
    });
  }
}
