import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-instance",
  templateUrl:
    "../../../../presentation/web/deployments/ecl2/instance/instance.component.html",
})
export class InstanceComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
