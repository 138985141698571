import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-port",
  templateUrl:
    "../../../../../presentation/web/deployments/ecl2/network/port/port.component.html",
})
export class PortComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
