import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AppConstant } from "src/app/app.constant";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UsersService } from "src/app/business/admin/users/users.service";
import { LocalStorageService } from "src/app/modules/services/shared/local-storage.service";
import { OrchestrationService } from "../../../../base/orchestration/orchestration.service";
import { ScriptService } from "../../../../tenants/scripts/script.service";
import * as _ from "lodash";
import { CommonService } from "src/app/modules/services/shared/common.service";

@Component({
  selector: "app-alert-notification",
  templateUrl: "../alert-notifications/alert-notifications.component.html",
})
export class AlertsNotificationComponent {
  alertNotifyForm: FormGroup;
  usersList = [];
  @Input() nodeObj = {};
  userstoragedata: any = {};
  orchestrationList = [];
  scriptList = [];
  dataLoading = false;
  levelsList = AppConstant.ALERT_LEVELS.LEVELS;
  alertList: any;
  listOfData: any;
  configuredNotifications = [
    {
      type: "Alert",
      notifiers: [],
      configuration: "",
    },
  ];
  alertNotificationErrObj = {
    alertType: AppConstant.VALIDATIONS.CICDPROVIDERS.NAME,
    notifiers: AppConstant.VALIDATIONS.CICDPROVIDERS.BRANCH,
    condition: AppConstant.VALIDATIONS.CICDPROVIDERS.USERNAME,
    metrics: AppConstant.VALIDATIONS.CICDPROVIDERS.ACCESSTOKEN,
    level: AppConstant.VALIDATIONS.CICDPROVIDERS.URL,
  };
  constructor(
    private userService: UsersService,
    private fb: FormBuilder,
    // private orchestrationService: OrchestrationService,
    // private scriptService: ScriptService,
    private commonService: CommonService,
    private localStorageService: LocalStorageService
  ) {
    this.userstoragedata = this.localStorageService.getItem(
      AppConstant.LOCALSTORAGE.USER
    );
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {
    this.alertNotifyForm = this.fb.group({
      alertTypeMethod: ["alert"],
      alertType: [null, [Validators.required]],
      notifiers: [null, [Validators.required]],
      condition: [null, [Validators.required]],
      metrics: [null, [Validators.required]],
      level: [null, [Validators.required]],
    });
    this.levelsList;
    this.getUsers();
    // this.getOrchestrationList();
    // this.getScriptList();
    this.getRules();
  }

  rulesList = [];
  getRules() {
    this.commonService
      .allSystemRules({
        tenantid: this.userstoragedata.tenantid,
        lookupkey: "SYSTEM_RULES",
        status: AppConstant.STATUS.ACTIVE,
      })
      .subscribe(
        (result) => {
          let response = JSON.parse(result._body);
          this.rulesList = _.orderBy(response.data, ["displayorder"], ["asc"]);
        },
        (err) => {
          this.rulesList = [];
          console.log(err);
        }
      );
  }
  // getOrchestrationList() {
  //   let condition: any = {
  //     status: AppConstant.STATUS.ACTIVE,
  //     tenantid: this.userstoragedata.tenantid,
  //   };

  //   this.orchestrationService.all(condition).subscribe((res) => {
  //     const response = JSON.parse(res._body);
  //     if (response.status) {
  //       this.orchestrationList = response.data;
  //     } else {
  //       this.orchestrationList = [];
  //     }
  //   });
  // }
  getUsers() {
    this.userService
      .allUsers({
        tenantid: this.userstoragedata.tenantid,
        status: AppConstant.STATUS.ACTIVE,
      })
      .subscribe((res) => {
        const response = JSON.parse(res._body);
        if (response.status) {
          this.usersList = response.data;
        }
      });
  }
  // getScriptList() {
  //   let condition: any = {
  //     status: AppConstant.STATUS.ACTIVE,
  //     tenantid: this.userstoragedata.tenantid,
  //   };
  //   this.scriptService.all(condition).subscribe((result) => {
  //     let response = {} as any;
  //     response = JSON.parse(result._body);
  //     if (response.status) {
  //       this.scriptList = response.data;
  //     } else {
  //       this.scriptList = [];
  //     }
  //   });
  // }
  deleteNotification(index: number): void {
    this.configuredNotifications.splice(index, 1);
  }
  getReceiversFor(receivers: string[]): string {
    return receivers.join(", ");
  }
}
